import React, {useState, useEffect, useRef } from "react";
import "../styles/innovacion.scoped.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const descargable1 = "/images/Logo_VEGASOTUELAMOS.ai";
const descargable2 = "/images/Fotos-Prensa-Vegasotuelamos.zip";

const tarro = "/images/innovacion-inconformismo-tarro.png";
const queso = "/images/innovacion-inconformismo-queso.png";
const oveja = "/images/innovacion-inconformismo-oveja.png";
const quesoID = "/images/home_queso_innovacion.png";
const laboratorio = "/images/innovacion-laboratorio.png";

const Prensa = () => {
   const { t } = useTranslation(); 
   
   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="cabeceraBig bgPrensa overlay">
            <div className="cabeceraTituloContenedor">
               <h1 className="cabecera">
                  <Trans i18nKey="prensa-titulo" components={{ span: <span /> }} />
               </h1>
               <p className="cabeceraSubtitulo">
                  <Trans i18nKey="prensa-subtitulo" components={{ span: <span /> }} />
               </p>
            </div>
         </div>
         <div className="prensa gradientePrensa">
            <h1 className="titulo w-100">
               <Trans i18nKey="descargables" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-4 mb-4"></div>
            <div className="container">
               <div className="row mt-5">
               <div className="col-1"></div>
                  <div className="col-md-5 col-12">
                     <div className="box-descargable">
                        <h3>
                           <Trans i18nKey="descargable1-titulo" components={{ span: <span /> }} />
                        </h3>
                        <p><Trans i18nKey="descargable1-subtitulo" components={{ span: <span /> }} /></p>
                        <a href={descargable1} className="link-descargable">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                              <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                           </svg><Trans i18nKey="descargar" />
                        </a>
                     </div>
                  </div>
                  <div className="col-md-5 col-12">
                     <div className="box-descargable">
                        <h3>
                           <Trans i18nKey="descargable2-titulo" components={{ span: <span /> }} />
                        </h3>
                        <p><Trans i18nKey="descargable2-subtitulo" components={{ span: <span /> }} /></p>
                        <a href={descargable2} className="link-descargable">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                              <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                           </svg><Trans i18nKey="descargar" />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-1"></div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default Prensa;