import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { checkWebp } from "../utils/checkWebp";
import { useTranslation, Trans } from 'react-i18next';

const logo = "/images/vega_sotuelamos_logo.svg";
const logoCorto = "/images/vega_sotuelamos_logoS.svg";
const facebook_icon = "/images/icon-facebook.svg";
const instagram_icon = "/images/icon-instagram.svg";
const linkedin_icon = "/images/icon-linkedin.svg";
const cunaManchego = checkWebp("/images/queso-manchego-curado-cuna.png");
const cunaManchegoEcologico = checkWebp("/images/queso-manchego-ecologico-curado-cuna.png");
const cunaManchegoLecheCruda = checkWebp("/images/queso-manchego-cruda-anejo-cuna.png");
const cunaOveja = checkWebp("/images/queso-oveja-tierno-cuna.png");
const cunaOvejaLecheCruda = checkWebp("/images/queso-oveja-cruda-curado-cuna.png");
const tarrinaCrema = "/images/categoria-cremas-de-queso-de-oveja-vega-sotuelamos.png";
const cunaRecetas = checkWebp("/images/queso-oveja-diablo-cuna.png");
const carrito = "/images/carrito-compra.svg";

const Header = () => {
   const { t } = useTranslation();
   const { i18n } = useTranslation();

   const handleChangeLanguage = (language, event) => {
      event.preventDefault();
      i18n.changeLanguage(language);
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   
      const handleScroll = () => {
   
         if(window.pageYOffset > 100){
            document.getElementsByClassName("navbar-brand")[0].style.display = "none";
            document.getElementsByClassName("logoAbreviado")[0].style.display = "block";
         }else{
            document.getElementsByClassName("navbar-brand")[0].style.display = "block";
            document.getElementsByClassName("logoAbreviado")[0].style.display = "none";
         }
      };
   
      window.addEventListener("scroll", handleScroll);
   
      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   
   }, []);

   return(
      <Navbar expand="lg">
         <div className="topMenu">
            <div className="topMenu_lang">
               <a href="#" className="topMenu-nav-link" style={{ marginRight: "10px",}} onClick={(event) => handleChangeLanguage('es', event)}>ESP</a>
               <a className="topMenu-nav-link" style={{ cursor: "default",}}>|</a>
               <a href="#" className="topMenu-nav-link" style={{ marginLeft: "10px",}} onClick={(event) => handleChangeLanguage('en', event)}>ENG</a>
            </div>
            <div className="topMenu_sub1">
               <div className="d-block">
                  <div className="iconos-sociales">
                     <a href="https://www.instagram.com/vegasotuelamos" target="_blank">
                        <div className="icono-instagram">
                           <img src={instagram_icon} alt="icono instagram"/>
                        </div>
                     </a>
                     <a href="https://www.facebook.com/VegaSotuelamos" target="_blank">
                        <div className="icono-facebook">
                           <img src={facebook_icon} alt="icono facebook"/>
                        </div>
                     </a>
                     <a href="https://www.linkedin.com/company/quesosvegasotuelamos" target="_blank">
                        <div className="icono-instagram">
                           <img src={linkedin_icon} alt="icono linkedin"/>
                        </div>
                     </a>
                  </div>
               </div>
               <div>
                  <NavLink to="https://www.vegasotuelamos.com/blog/" target="_blank" className="topMenu-nav-link"><Trans i18nKey="BLOG" /></NavLink>
                  <NavLink to="/prensa" className="topMenu-nav-link"><Trans i18nKey="PRENSA" /></NavLink>
               </div>
            </div>
            <div className="topMenu_sub2">
               <a className="topMenu-Button" href="//vegasotuelamos.com/tienda" target="_blank"><img className="carrito" src={carrito} />&nbsp;<Trans i18nKey="quiero-comprar" /></a>
            </div>
         </div>
         <Navbar.Brand href="/">
            <img style={{ width: "280px"}} src={logo} alt="logo"/>
         </Navbar.Brand>
         <Container>
            <a href="/"><img className="logoAbreviado" src={logoCorto} /></a>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-center navbar-nav-scroll">
               <Nav className="ml-auto">
                  <NavLink to="/somos" className="nav-link"><Trans i18nKey="menu-somos" /></NavLink>
                  <NavLink to="/innovacion" className="nav-link"><Trans i18nKey="menu-innovacion" /></NavLink>
                  <NavDropdown title={<Trans i18nKey="menu-quesos" />}>
                     <div className="menu-quesos-mobile">
                        <NavLink to="/quesos-manchegos" className="submenu-mobile"><Trans i18nKey="menu-quesos-manchegos" /></NavLink>
                           <NavLink to="/queso-manchego-semicurado" className="item-submenu-mobile"><Trans i18nKey="menu-semicurado-leche-oveja" /></NavLink>
                           <NavLink to="/queso-manchego-curado" className="item-submenu-mobile"><Trans i18nKey="menu-curado-leche-oveja" /></NavLink>
                           <NavLink to="/queso-manchego-anejo" className="item-submenu-mobile"><Trans i18nKey="menu-anejo-leche-oveja" /></NavLink>

                        <NavLink to="/quesos-manchegos-ecologicos" className="submenu-mobile"><Trans i18nKey="menu-quesos-manchegos-ecologicos" /></NavLink>
                           <NavLink to="/queso-manchego-ecologico-semicurado" className="item-submenu-mobile"><Trans i18nKey="menu-semicurado-ecologico" /></NavLink>
                           <NavLink to="/queso-manchego-ecologico-curado" className="item-submenu-mobile"><Trans i18nKey="menu-curado-ecologico" /></NavLink>
                        
                        <NavLink to="/quesos-manchegos-leche-cruda" className="submenu-mobile"><Trans i18nKey="menu-quesos-manchegos-cruda" /></NavLink>
                           <NavLink to="/queso-manchego-leche-cruda-semicurado" className="item-submenu-mobile"><Trans i18nKey="menu-semicurado-cruda" /></NavLink>
                           <NavLink to="/queso-manchego-leche-cruda-curado" className="item-submenu-mobile"><Trans i18nKey="menu-curado-cruda" /></NavLink>
                           <NavLink to="/queso-manchego-leche-cruda-anejo" className="item-submenu-mobile"><Trans i18nKey="menu-anejo-cruda" /></NavLink>
                        
                        <NavLink to="/quesos-oveja" className="submenu-mobile"><Trans i18nKey="menu-quesos-oveja" /></NavLink>
                           <NavLink to="/queso-oveja-tierno" className="item-submenu-mobile"><Trans i18nKey="menu-queso-oveja-tierno" /></NavLink>
                           <NavLink to="/queso-oveja-semicurado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-oveja-semicurado" /></NavLink>
                           <NavLink to="/queso-oveja-curado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-oveja-curado" /></NavLink>
                           <NavLink to="/queso-oveja-anejo" className="item-submenu-mobile"><Trans i18nKey="menu-queso-oveja-anejo" /></NavLink>
                        
                        <NavLink to="/quesos-oveja-leche-cruda" className="submenu-mobile"><Trans i18nKey="menu-quesos-leche-cruda" /> </NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-semicurado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-semicurado-cruda" /></NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-curado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-curado-cruda" /></NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-anejo" className="item-submenu-mobile"><Trans i18nKey="menu-queso-anejo-cruda" /></NavLink>
                        
                        <NavLink to="/quesos-oveja-recetas" className="submenu-mobile"><Trans i18nKey="menu-quesos-recetas-exclusivas" /></NavLink>
                           <NavLink to="/queso-oveja-ahumado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-ahumado" /></NavLink>
                           <NavLink to="/queso-oveja-anchoa" className="item-submenu-mobile"><Trans i18nKey="menu-queso-anchoa" /></NavLink>
                           <NavLink to="/queso-oveja-boletus" className="item-submenu-mobile"><Trans i18nKey="menu-queso-boletus" /></NavLink>
                           <NavLink to="/queso-oveja-aceituna-negra" className="item-submenu-mobile"><Trans i18nKey="menu-queso-aceituna-negra" /></NavLink>
                           <NavLink to="/queso-oveja-ajo-negro" className="item-submenu-mobile"><Trans i18nKey="menu-queso-ajo-negro" /></NavLink>
                           <NavLink to="/queso-oveja-trufa-negra" className="item-submenu-mobile"><Trans i18nKey="menu-queso-trufa-negra" /></NavLink>
                           <NavLink to="/queso-oveja-trufa-blanca" className="item-submenu-mobile"><Trans i18nKey="menu-queso-trufa-blanca" /></NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-trufa-negra" className="item-submenu-mobile"><Trans i18nKey="menu-queso-cruda-trufa-negra" /></NavLink>
                           <NavLink to="/queso-oveja-diablo" className="item-submenu-mobile"><Trans i18nKey="menu-queso-diablo" /></NavLink>
                           <NavLink to="/queso-oveja-jalapenos" className="item-submenu-mobile"><Trans i18nKey="menu-queso-jalapenos" /></NavLink>
                           <NavLink to="/queso-oveja-infierno" className="item-submenu-mobile"><Trans i18nKey="menu-queso-infierno" /></NavLink>
                           <NavLink to="/queso-oveja-miel-oregano" className="item-submenu-mobile"><Trans i18nKey="menu-queso-miel-oregano" /></NavLink>
                           <NavLink to="/queso-oveja-romero" className="item-submenu-mobile"><Trans i18nKey="menu-queso-romero" /></NavLink>
                           <NavLink to="/queso-oveja-tradicion" className="item-submenu-mobile"><Trans i18nKey="menu-queso-tradicion" /></NavLink>

                        <NavLink to="/quesos-oveja-crema" className="submenu-mobile"><Trans i18nKey="menu-quesos-crema" /> </NavLink>
                           <NavLink to="/queso-oveja-crema-ahumado" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-ahumado" /></NavLink>
                           <NavLink to="/queso-oveja-crema-ajo-negro" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-ajo-negro" /></NavLink>
                           <NavLink to="/queso-oveja-crema-diablo" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-diablo" /></NavLink>
                           <NavLink to="/queso-oveja-crema-oregano-miel" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-oregano-miel" /></NavLink>
                           <NavLink to="/queso-oveja-crema-romero" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-romero" /></NavLink>
                           <NavLink to="/queso-oveja-crema-trufa" className="item-submenu-mobile"><Trans i18nKey="menu-queso-crema-trufa" /></NavLink>
                     </div>
                     <div className="menu-quesos-desktop">
                        <div className="dropDownSection">
                           <img src={cunaManchego} alt="cuña de queso"/>
                           <NavLink to="/quesos-manchegos" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-manchegos" /></NavLink>
                           <div className="dropDownLinea1"></div>
                           <NavLink to="/queso-manchego-semicurado" className="dropdown-item"><Trans i18nKey="menu-desktop-semicurado-leche-oveja" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-manchego-curado" className="dropdown-item"><Trans i18nKey="menu-desktop-curado-leche-oveja" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-manchego-anejo" className="dropdown-item"><Trans i18nKey="menu-desktop-anejo-leche-oveja" components={{ span: <span /> }} /></NavLink>
                        </div>
                        <div className="dropDownSection">
                           <img src={cunaManchegoEcologico} alt="cuña de queso"/>
                           <NavLink to="/quesos-manchegos-ecologicos" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-manchegos-ecologicos" /></NavLink>
                           <div className="dropDownLinea2"></div>
                           <NavLink to="/queso-manchego-ecologico-semicurado" className="dropdown-item"><Trans i18nKey="menu-desktop-semicurado-ecologico" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-manchego-ecologico-curado" className="dropdown-item"><Trans i18nKey="menu-desktop-curado-ecologico" components={{ span: <span /> }} /></NavLink>
                        </div>
                        <div className="dropDownSection">
                           <img src={cunaManchegoLecheCruda} alt="cuña de queso"/>
                           <NavLink to="/quesos-manchegos-leche-cruda" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-manchegos-cruda" /></NavLink>
                           <div className="dropDownLinea3"></div>
                           <NavLink to="/queso-manchego-leche-cruda-semicurado" className="dropdown-item"><Trans i18nKey="menu-desktop-semicurado-cruda" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-manchego-leche-cruda-curado" className="dropdown-item"><Trans i18nKey="menu-desktop-curado-cruda" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-manchego-leche-cruda-anejo" className="dropdown-item"><Trans i18nKey="menu-desktop-anejo-cruda" components={{ span: <span /> }} /></NavLink>
                        </div>
                        <div className="dropDownSection">
                           <img src={cunaOveja} alt="cuña de queso"/>
                           <NavLink to="/quesos-oveja" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-oveja" /></NavLink>
                           <div className="dropDownLinea4"></div>
                           <NavLink to="/queso-oveja-tierno" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-oveja-tierno" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-semicurado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-oveja-semicurado" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-curado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-oveja-curado" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-anejo" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-oveja-anejo" components={{ span: <span /> }} /></NavLink>
                        </div>
                        <div className="dropDownSection">
                           <img src={cunaOvejaLecheCruda} alt="cuña de queso"/>
                           <NavLink to="/quesos-oveja-leche-cruda" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-leche-cruda" /></NavLink>
                           <div className="dropDownLinea5"></div>
                           <NavLink to="/queso-oveja-leche-cruda-semicurado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-semicurado-cruda" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-curado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-curado-cruda" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-leche-cruda-anejo" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-anejo-cruda" components={{ span: <span /> }} /></NavLink>
                        </div>
                        <div className="dropDownSection">
                           <img src={cunaRecetas} alt="cuña de queso"/>
                           <NavLink to="/quesos-oveja-recetas" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-recetas-exclusivas" /></NavLink>
                           <div className="dropDownLinea6"></div>
                           <div className="d-flex">
                              <div>
                                 <NavLink to="/queso-oveja-ahumado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-ahumado" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-anchoa" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-anchoa" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-boletus" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-boletus" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-aceituna-negra" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-aceituna-negra" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-ajo-negro" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-ajo-negro" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-trufa-negra" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-trufa-negra" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-trufa-blanca" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-trufa-blanca" components={{ span: <span /> }} /></NavLink>
                              </div>
                              <div>
                                 <NavLink to="/queso-oveja-leche-cruda-trufa-negra" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-cruda-trufa-negra" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-diablo" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-diablo" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-jalapenos" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-jalapenos" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-infierno" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-infierno" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-miel-oregano" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-miel-oregano" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-romero" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-romero" components={{ span: <span /> }} /></NavLink>
                                 <NavLink to="/queso-oveja-tradicion" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-tradicion" components={{ span: <span /> }} /></NavLink>
                              </div>
                           </div>
                        </div>
                        <div className="dropDownSection">
                           <img src={tarrinaCrema} alt="cuña de queso"/>
                           <NavLink to="/quesos-oveja-crema" className="dropdown-item tituloDropDown"><Trans i18nKey="menu-desktop-quesos-crema" /></NavLink>
                           <div className="dropDownLinea7"></div>
                           <NavLink to="/queso-oveja-crema-ahumado" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-ahumado" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-crema-ajo-negro" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-ajo-negro" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-crema-diablo" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-diablo" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-crema-oregano-miel" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-oregano-miel" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-crema-romero" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-romero" components={{ span: <span /> }} /></NavLink>
                           <NavLink to="/queso-oveja-crema-trufa" className="dropdown-item"><Trans i18nKey="menu-desktop-queso-crema-trufa" components={{ span: <span /> }} /></NavLink>
                        </div>
                     </div>
                  </NavDropdown>
                  <NavLink to="/premios" className="nav-link"><Trans i18nKey="menu-premios" /></NavLink>
                  <NavLink to="/ovejas" className="nav-link"><Trans i18nKey="menu-ovejas" /></NavLink>
                  <NavLink to="https://qausa.vegasotuelamos.com/" target="_blank" className="nav-link"><Trans i18nKey="menu-qausa" /></NavLink>
                  <NavLink to="/contacto" className="nav-link"><Trans i18nKey="menu-contacto" /></NavLink>
               </Nav>
            </Navbar.Collapse>
         </Container>
      </Navbar>
   )
};

export default Header;