import React, { useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const AvisoLegal = () => {

   useEffect(() => {
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = 'https://consent.cookiebot.com/f730530e-09ef-49ae-a7d0-74276b765a4a/cd.js';
      script.type = 'text/javascript';
      script.async = true;
  
      const cookieDeclarationDiv = document.getElementById('CookieDeclaration');
      cookieDeclarationDiv.appendChild(script);
  
      return () => {
        // Limpiar el script si el componente se desmonta
        cookieDeclarationDiv.removeChild(script);
      };
   }, []);

   const { t } = useTranslation(); 
   
   return (
      <div>
         <Header />
         <div className="espacioHeader"></div>
         <div className="simple-page" id="cookies">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="politica-cookies-titulo" /></h1>
                     <h4><strong>1. NORMATIVA APLICABLE</strong></h4>
                     <p>El apartado segundo del artículo 22 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (en adelante, LSSI-CE), establece lo siguiente:</p>
                     <ul>
                        <li><em>Los prestadores de servicios podrán utilizar dispositivos de almacenamiento y recuperación de datos en equipos terminales de los destinatarios, a condición de que los mismos hayan dado su consentimiento después de que se les haya facilitado información clara y completa sobre su utilización, en particular, sobre los fines del tratamiento de los datos, con arreglo a lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, así como de conformidad con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPD-GDD).</em></li>
                        <li><em>Cuando sea técnicamente posible y eficaz, el consentimiento del destinatario para aceptar el tratamiento de los datos podrá facilitarse mediante el uso de los parámetros adecuados del navegador o de otras aplicaciones, siempre que aquél deba proceder a su configuración durante su instalación o actualización mediante una acción expresa a tal efecto.</em></li>
                        <li><em>Lo anterior no impedirá el posible almacenamiento o acceso de índole técnica al solo fin de efectuar la transmisión de una comunicación por una red de comunicaciones electrónicas o, en la medida que resulte estrictamente necesario, para la prestación de un servicio de la sociedad de la información expresamente solicitado por el destinatario.</em></li>
                     </ul>
                     <p>Según recoge la “Guía sobre el uso de las cookies”, publicada por la Agencia Española de Protección de Datos (en adelante, AEPD) en el año 2013, la LSSI-CE es aplicable a cualquier tipo de archivo o dispositivo que se descarga en el equipo terminal de un usuario con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación. La cookie es uno de esos dispositivos de uso generalizado por lo que, en adelante, denominaremos genéricamente estos dispositivos como cookies.</p>
                     <p>Quedan exceptuadas del cumplimiento de las obligaciones establecidas en el artículo 22.2 de la LSSI-CE las cookies utilizadas para alguna de las siguientes finalidades:</p>
                     <ul>
                        <li>Permitir únicamente la comunicación entre el equipo del usuario y la red.</li>
                        <li>Estrictamente prestar un servicio expresamente solicitado por el usuario.</li>
                     </ul>
                     <h4><strong>2. TIPOS DE COOKIES SEGÚN SU FINALIDAD</strong></h4>
                     <p>Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
                     <p>Tal como recoge la “Guía sobre el uso de las cookies” de la AEPD, según la finalidad para la que se traten los datos obtenidos a través de las cookies, podemos distinguir entre:</p>
                     <ul>
                        <li><strong>Cookies técnicas: </strong>Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</li>
                        <li><strong>Cookies de personalización: </strong>Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</li>
                        <li><strong>Cookies de análisis: </strong>Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
                        <li><strong>Cookies publicitarias: </strong>Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
                        <li><strong>Cookies de publicidad comportamental: </strong>Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
                     </ul>
                     <div id="CookieDeclaration"></div>
                     <h4><strong>3. PRINCIPIO DE INFORMACIÓN</strong></h4>
                     <p>En cumplimiento de lo establecido en el artículo 5 de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (en adelante, LOPD), le informamos de modo expreso, preciso e inequívoco que la información que se obtenga a través de las cookies propias que se instalen en su ordenador será utilizada con las siguientes finalidades:</p>
                     <p>Los destinatarios de la información que se obtenga a través de las cookies que se instalen en su ordenador serán las siguientes entidades:</p>
                     <p>En su caso, las entidades directamente relacionadas con el editor responsable de la web.</p>
                     <h4><strong>4. PRINCIPIO DEL CONSENTIMIENTO</strong></h4>
                     <p>El consentimiento para la instalación de las cookies se entenderá prestado a través de la marcación de la casilla relativa a la aceptación de la “Política de cookies” dispuesta al efecto en nuestra página web.</p>
                     <p>En los casos en que el usuario no manifieste expresamente si acepta o no la instalación de las cookies, pero continúe utilizando la página web o la aplicación se entenderá que éste ha dado su consentimiento, informándole expresamente nuestra entidad de la posibilidad de bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
                     <h4><strong>5. CARÁCTER FACULTATIVO DE LA INSTALACIÓN DE COOKIES</strong></h4>
                     <p>Si bien la aceptación de la instalación de las cookies en su ordenador es facultativa para usted, la negativa a su instalación puede suponer que la funcionalidad de la página web quede limitada o no sea posible, lo cual imposibilitaría la prestación de servicios por parte de nuestra entidad a través de la misma.</p>
                     <h4><strong>6. DESACTIVACIÓN DE COOKIES</strong></h4>
                     <p>El usuario en todo momento podrá cambiar la configuración de las cookies, bloquearlas o desactivarlas. Para ello le facilitamos el modo en los principales navegadores.</p>
                     <ul>
                        <li>CHROME: <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                        <li>EXPLORER: <a href="http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies" target="_blank">http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies</a></li>
                        <li>FIREFOX: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
                        <li>SAFARI: <a href="https://support.apple.com/es-es/HT201265" target="_blank">https://support.apple.com/es-es/HT201265</a></li>
                     </ul>
                     <p>Así como las cookies a terceros Google Analytics</p>
                     <p><a href="http://www.google.es/intl/es/policies/technologies/cookies/" target="_blank">http://www.google.es/intl/es/policies/technologies/cookies/</a></p>
                     <p>QUESOS VEGA SOTUELAMOS SL. no se hace responsable del contenido y veracidad de las políticas de cookies de los terceros.</p>
                     <h4><strong>7. PRINCIPIO DE SEGURIDAD DE LOS DATOS</strong></h4>
                     <p><strong>QUESOS VEGA SOTUELAMOS SL</strong>. se compromete al cumplimiento de su obligación de secreto respecto de los datos de carácter personal y de su deber de guardarlos y adoptará todas las medidas de índole técnica y organizativa necesarias que garanticen la seguridad de los datos de carácter personal y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que estén expuestos, ya provengan de la acción humana o del medio físico o natural, desarrolladas en el Título VIII del Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>
                     <h4><strong>8. EJERCICIO DE DERECHOS</strong></h4>
                     <p>En cumplimiento de lo establecido en la LOPD y el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la misma, el destinatario del servicio puede ejercitar, en cualquier momento, sus derechos de acceso, rectificación, cancelación y oposición ante el responsable del fichero o del tratamiento, adjuntando fotocopia de su DNI.</p>
                     <h4><strong>9. RESPONSABLE DEL FICHERO O DEL TRATAMIENTO</strong></h4>
                     <p>El responsable del fichero o del tratamiento es QUESOS VEGA SOTUELAMOS SL., con dirección a efectos de notificaciones en <strong>Pol. Ind. Camporrosso C/ Ciudad Real, 3 02520  (Chinchilla de Montearagón) – Albacete.</strong></p>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
};

export default AvisoLegal;