import React, { useState, useEffect, useRef } from "react";
import "../styles/somos.scoped.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const tecnologia = "/images/somos-tecnologia.png";
const mapa = "/images/somos-paises-mapa.png";
const calidad = "/images/home_queso_calidad.png";
const diferenciacion = "/images/home_queso_diferenciacion.png";
const innovacion = "/images/home_queso_innovacion.png";
const compromiso = "/images/home_queso_compromiso.png";

const Somos = () => {
   const { t } = useTranslation();

   const [isHovered1, setIsHovered1] = useState(false);
   const [isHovered2, setIsHovered2] = useState(false);
   const [isHovered3, setIsHovered3] = useState(false);
   const [isHovered4, setIsHovered4] = useState(false);

   const [isSelectedQS1, setisSelectedQS1] = useState(true);
   const [isSelectedQS2, setisSelectedQS2] = useState(false);
   const [isSelectedQS3, setisSelectedQS3] = useState(false);
   const [isSelectedQS4, setisSelectedQS4] = useState(false);

   const selectedQS1 = () => {
      setisSelectedQS1(true);
      setisSelectedQS2(false);
      setisSelectedQS3(false);
      setisSelectedQS4(false);
   };

   const selectedQS2 = () => {
      setisSelectedQS1(false);
      setisSelectedQS2(true);
      setisSelectedQS3(false);
      setisSelectedQS4(false);
   };

   const selectedQS3 = () => {
      setisSelectedQS1(false);
      setisSelectedQS2(false);
      setisSelectedQS3(true);
      setisSelectedQS4(false);
   };

   const selectedQS4 = () => {
      setisSelectedQS1(false);
      setisSelectedQS2(false);
      setisSelectedQS3(false);
      setisSelectedQS4(true);
   };

   const settings = {
      className: "center",
      centerMode: false,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      speed: 500,
      responsive: [
         {
            breakpoint: 1024, // Punto de quiebre para tablet
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 768, // Punto de quiebre para dispositivos móviles
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 425,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ]
   };

   const [hoveredImages, setHoveredImages] = useState({
      francisco: false,
      mariaJose: false,
      david: false,
      joaquin: false,
      inmaculada: false,
      angelcarrizo: false,
   });

   const handleMouseEnter = (key) => {
      setHoveredImages({ ...hoveredImages, [key]: true });
   };

   const handleMouseLeave = (key) => {
      setHoveredImages({ ...hoveredImages, [key]: false });
   };

   useEffect(() => {
      // Objeto con IDs de elementos como claves y clases correspondientes como valores
      const elementosClases = {
         'tabla-animate': 'animate__fadeInDown',
      };

      // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
      const agregarClaseSiVisible = () => {
         Object.keys(elementosClases).forEach((id) => {
            const elemento = document.getElementById(id);

            // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
            if (elemento) {
               const elementoPosicion = elemento.getBoundingClientRect().top;
               const windowHeight = window.innerHeight;

               if (elementoPosicion < windowHeight) {
                  elemento.classList.add(elementosClases[id]);
               } else {
                  elemento.classList.remove(elementosClases[id]);
               }
            }
         });
      };

      // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
      document.addEventListener('scroll', agregarClaseSiVisible);

      // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
      return () => {
         document.removeEventListener('scroll', agregarClaseSiVisible);
      };
   }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

   return (
      <div>
         <Header />
         <div className="espacioHeader"></div>
         <div className="cabeceraBig bgSomos overlay">
            <div className="cabeceraTituloContenedor">
               <h1 className="cabecera animate__animated animate__fadeIn">
                  <Trans i18nKey="equipo-leche" components={{ span: <span /> }} />
               </h1>
               <p className="cabeceraSubtitulo animate__animated animate__fadeInUp"><Trans i18nKey="naturales-innovadores" /></p>
            </div>
         </div>
         <div className="tecnologiaYTradicion">
            <h1 className="titulo">
               <Trans i18nKey="tech-bocado" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-3 mb-4"></div>
            <p className="texto">
               <Trans i18nKey="intro-text-somos" components={{ b: <b /> }} />
            </p>
            <div className="image">
               <img src={tecnologia} alt="imagen tecnología" className="mt-5 animate__animated hideEl" id="tabla-animate" style={{ width: "400px" }} />
            </div>
         </div>
         <div className="quesoSorprendente">
            <h1 className="tituloBlanco">
               <Trans i18nKey="queremos-ser" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-3 mb-4"></div>
            <div>
               <div className="detalle-lista">
                  <div className="detalle" style={{ display: isSelectedQS1 ? 'flex' : 'none' }}>
                     <p className="textoFondo"><Trans i18nKey="somos-calidad" /></p>
                     <img src={calidad} style={{ height: "400px", }} />
                     <p className="texto"><Trans i18nKey="somos-calidad-texto" /></p>
                  </div>
                  <div className="detalle" style={{ display: isSelectedQS2 ? 'flex' : 'none' }}>
                     <p className="textoFondo"><Trans i18nKey="somos-diferenciacion" /></p>
                     <img src={diferenciacion} style={{ height: "400px", }} />
                     <p className="texto"><Trans i18nKey="somos-diferenciacion-texto" /></p>
                  </div>
                  <div className="detalle" style={{ display: isSelectedQS3 ? 'flex' : 'none' }}>
                     <p className="textoFondo"><Trans i18nKey="somos-innovacion" /></p>
                     <img src={innovacion} style={{ height: "400px", }} />
                     <p className="texto"><Trans i18nKey="somos-innovacion-texto" /></p>
                  </div>
                  <div className="detalle" style={{ display: isSelectedQS4 ? 'flex' : 'none' }}>
                     <p className="textoFondo"><Trans i18nKey="somos-compromiso" /></p>
                     <img src={compromiso} style={{ height: "400px", }} />
                     <p className="texto"><Trans i18nKey="somos-compromiso-texto" /></p>
                  </div>
               </div>
               <div className="points">
                  <a className="point" onClick={selectedQS1} style={{ background: isSelectedQS1 || isHovered1 ? 'white' : 'transparent' }} onMouseOver={() => setIsHovered1(true)} onMouseOut={() => setIsHovered1(false)}></a>
                  <a className="point" onClick={selectedQS2} style={{ background: isSelectedQS2 || isHovered2 ? 'white' : 'transparent' }} onMouseOver={() => setIsHovered2(true)} onMouseOut={() => setIsHovered2(false)}></a>
                  <a className="point" onClick={selectedQS3} style={{ background: isSelectedQS3 || isHovered3 ? 'white' : 'transparent' }} onMouseOver={() => setIsHovered3(true)} onMouseOut={() => setIsHovered3(false)}></a>
                  <a className="point" onClick={selectedQS4} style={{ background: isSelectedQS4 || isHovered4 ? 'white' : 'transparent' }} onMouseOver={() => setIsHovered4(true)} onMouseOut={() => setIsHovered4(false)}></a>
               </div>
            </div>
         </div>
         <div className="paises">
            <p className="texto"><Trans i18nKey="somos-estamos-en" /></p>
            <div className="contenedorTitulo">
               <h1 className="titulo">75 <span className="titulo2"><Trans i18nKey="somos-paises" /></span></h1>
            </div>
            <div className="image">
               <img src={mapa} alt="imagen tecnología" />
            </div>
         </div>
         <div className="amigos">
            <h1 className="titulo">
               <Trans i18nKey="dos-amigos" components={{ span: <span /> }} />
            </h1>
            <p className="texto"><Trans i18nKey="la-historia" components={{ b: <b /> }} /></p>
            <div className="row align-items-center justify-content-center">
               <div className="col-6">
                  <div className="video-amigos text-center mt-5">
                     <video controls playsInline>
                        <source src="/images/video-vegasotuelamos.mp4" type="video/mp4" />
                        Tu navegador no admite la reproducción de videos.
                     </video>
                  </div>
               </div>
            </div>
         </div>
         <div className="equipo">
            <p className="texto"><Trans i18nKey="nuestro" /></p>
            <h1 className="titulo"><Trans i18nKey="equipo" /></h1>
            <div className="row justify-content-center">
               <div className="col-sm-7">
                  <div className="tarjetas">
                     {/* <Slider {...settings}> */}
                     <div className="row">
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.francisco ? "/images/francisco-2.jpg" : "/images/francisco.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('francisco')}
                                 onMouseLeave={() => handleMouseLeave('francisco')}
                              />
                              <p className="nombreCarouselPersona">Francisco<br />Mateo</p>
                              <p className="cargoCarouselPersona">Director General</p>
                           </div>
                        </div>
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.mariaJose ? "/images/maria-jose.jpg" : "/images/maria-jose-2.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('mariaJose')}
                                 onMouseLeave={() => handleMouseLeave('mariaJose')}
                              />
                              <p className="nombreCarouselPersona">María José<br />Gromada</p>
                              <p className="cargoCarouselPersona">Gerente</p>
                           </div>
                        </div>
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.david ? "/images/david-2.jpg" : "/images/david.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('david')}
                                 onMouseLeave={() => handleMouseLeave('david')}
                              />
                              <p className="nombreCarouselPersona">David<br />Villena</p>
                              <p className="cargoCarouselPersona">Gerente de planta y Responsable de I+D</p>
                           </div>
                        </div>
                     </div>

                     <div className="row">
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.joaquin ? "/images/joaquin-2.jpg" : "/images/joaquin.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('joaquin')}
                                 onMouseLeave={() => handleMouseLeave('joaquin')}
                              />
                              <p className="nombreCarouselPersona">Joaquín<br />Bautista</p>
                              <p className="cargoCarouselPersona">Coord. Pedidos y Administración</p>
                           </div>
                        </div>
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.inmaculada ? "/images/inmaculada-2.jpg" : "/images/inmaculada.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('inmaculada')}
                                 onMouseLeave={() => handleMouseLeave('inmaculada')}
                              />
                              <p className="nombreCarouselPersona">Inmaculada<br />Arenas</p>
                              <p className="cargoCarouselPersona">Responsable de calidad</p>
                           </div>
                        </div>
                        <div className="col-4">
                           <div className="tarjeta">
                              <img
                                 src={hoveredImages.angelcarrizo ? "/images/angelcarrizo-2.jpg" : "/images/angelcarrizo.jpg"}
                                 alt="directivo vegasotuelamos"
                                 onMouseEnter={() => handleMouseEnter('angelcarrizo')}
                                 onMouseLeave={() => handleMouseLeave('angelcarrizo')}
                              />
                              <p className="nombreCarouselPersona">Ángel<br />Carrizo</p>
                              <p className="cargoCarouselPersona">Jefe de Planta</p>
                           </div>
                        </div>
                     </div>
                     {/* </Slider> */}
                  </div>
               </div>
            </div>
         </div>
         <div className="container-fluid">
            <div className="row">
               <div className="col-12">
                  <div className="imagen-equipo">
                     <img src="/images/equipo-vegasotuelamos.jpg" alt="imagen-equipo" />
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
};

export default Somos;