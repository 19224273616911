import React, {useState, useEffect, useRef } from "react";
import "../styles/premios.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const quesopremio = "/images/premios-queso1.png";
const corona = "/images/premios-corona.png";
const premio = "/images/premios-premio1.png";
const premio2 = "/images/premios-premio2.png";

const PremioQuesoOvejaSemicurado = "/images/Queso-MANCHEGO-SEMICURADO-de-leche-de-oveja-premio.png";
const PremioQuesoManchegoCurado = "/images/Queso-MANCHEGO-CURADO-de-leche-de-oveja-premio.png";
const PremioQuesoManchegoAnejo = "/images/Queso-MANCHEGO-ANEJO-de-leche-de-oveja-premio.png";
const PremioQuesoTierno = "/images/Queso-de-leche-de-oveja-TIERNO-premio.png";
const PremioQuesoTrufaNegra = "/images/Queso-curado-de-oveja-con-TRUFA-NEGRA-premio.png";
const PremioQuesoTrufaBlanca = "/images/Queso-curado-de-oveja-con-TRUFA-BLANCA-premio.png";
const PremioQuesoBoletus = "/images/Queso-curado-de-oveja-con-BOLETUS-premio.png";
const PremioQuesoAceitunaNegra = "/images/Queso-curado-de-oveja-con-ACEITUNA-NEGRA-premio.png";
const PremioQuesoAjoNegro = "/images/Queso-curado-de-oveja-con-AJO-NEGRO-premio.png";
const PremioQuesoDiablo = "/images/Queso-curado-de-oveja-con-DIABLO-premio.png";
const PremioQuesoJalapeno = "/images/queso-curado-de-oveja-con-JALAPENO-premio.png";
const PremioQuesoRomero = "/images/queso-curado-de-oveja-al-ROMERO-premio.png";
const PremioQuesoTradicion = "/images/queso-anejo-oveja-TRADICION-premio.png";
const PremioQuesoLecheCurado = "/images/Queso-DE-LECHE-de-oveja-CURADO-premio.png";
const PremioQuesoLecheArtesanoCurado = "/images/queso-de-oveja-curado-leche-cruda-3-kg-premio-web.png";
const PremioQuesoAhumado = "/images/Queso-de-oveja-AHUMADO-premio.png";
const PremioQuesoMielOregano = "/images/Queso-CURADO-DE-OVEJA-CUBIERTO-DE-MIEL-Y-OREGANO-premio.png";

const carrito = "/images/carrito-compra.svg";

const Somos = () => {
   const { t } = useTranslation(); 

   useEffect(() => {
      // Objeto con IDs de elementos como claves y clases correspondientes como valores
      const animatePremio = 'animate__fadeIn';

      const elementosClases = {
         'queso-premio-animate': 'animate__jackInTheBox',
         'premio1': animatePremio,
         'premio2': animatePremio,
         'premio3': animatePremio,
         'premio4': animatePremio,
         'premio5': animatePremio,
         'premio6': animatePremio,
         'premio7': animatePremio,
         'premio8': animatePremio,
         'premio9': animatePremio,
         'premio10': animatePremio,
         'premio11': animatePremio,
         'premio12': animatePremio,
         'premio13': animatePremio,
         'premio14': animatePremio,
         'premio14_2': animatePremio,
         'premio15': animatePremio,
         'premio16': animatePremio,
      };

      // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
      const agregarClaseSiVisible = () => {
         Object.keys(elementosClases).forEach((id) => {
         const elemento = document.getElementById(id);

         // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
         if (elemento) {
            const elementoPosicion = elemento.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (elementoPosicion < windowHeight) {
               elemento.classList.add(elementosClases[id]);
            } else {
               elemento.classList.remove(elementosClases[id]);
            }
         }
         });
      };

      // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
      document.addEventListener('scroll', agregarClaseSiVisible);

      // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
      return () => {
         document.removeEventListener('scroll', agregarClaseSiVisible);
      };
   }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="cabeceraBig bgPremios overlay">
            <div className="cabeceraTituloContenedor">
               <h1 className="cabecera animate__animated animate__fadeIn">
                  <Trans i18nKey="quesos-premiados-internacionalmente" components={{ span: <span /> }} />
               </h1>
               <p className="cabeceraSubtitulo animate__animated animate__fadeInUp">
                  <Trans i18nKey="reconocimiento" />
               </p>
            </div>
         </div>
         <div className="premioConsecuencia">
            <h1 className="titulo">
               <Trans i18nKey="premio-consecuencia" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-3 mb-4"></div>
            <p className="texto">
               <Trans i18nKey="premio-consecuencia-text" components={{ b: <b /> }} />
            </p>
            <div className="image">
               <img src={quesopremio} alt="imagen queso premio" className="animate__animated hideEl" id="queso-premio-animate" />
            </div>
            <p className="texto">
               <Trans i18nKey="premio-consecuencia-text2" components={{ b: <b /> }} />
            </p>
         </div>
         <div className="quesosPremiados">
            {/* <h1 className="titulo">DESCUBRE NUESTROS <span className="titulo2">QUESOS PREMIADOS</span></h1>
            <div className="lineaRojaQuesos mt-3"></div> */}
            <div className="titulosQuesos">
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoOvejaSemicurado} className="justify-content-end premio-img animate__animated hideEl" id="premio1"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso1" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color1"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoManchegoCurado} className="justify-content-end premio-img animate__animated hideEl" id="premio2"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso2" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color2"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoManchegoAnejo} className="justify-content-end premio-img animate__animated hideEl" id="premio3"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso3" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color3"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoTierno} className="justify-content-end premio-img animate__animated hideEl" id="premio4"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso4" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color4"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoLecheCurado} className="justify-content-end premio-img animate__animated hideEl" id="premio14"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso5" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color5"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoLecheArtesanoCurado} className="justify-content-end premio-img animate__animated hideEl" id="premio14_2"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso5-2" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color5-2"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoTrufaNegra} className="justify-content-end premio-img animate__animated hideEl" id="premio5"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso6" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color6"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoTrufaBlanca} className="justify-content-end premio-img animate__animated hideEl" id="premio6"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso7" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color7"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoBoletus} className="justify-content-end premio-img animate__animated hideEl" id="premio7"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso8" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color8"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoAceitunaNegra} className="justify-content-end premio-img animate__animated hideEl" id="premio8"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso9" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color9"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoAjoNegro} className="justify-content-end premio-img animate__animated hideEl" id="premio9"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso10" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color10"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoDiablo} className="justify-content-end premio-img animate__animated hideEl" id="premio10"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso11" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color11"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoJalapeno} className="justify-content-end premio-img animate__animated hideEl" id="premio11"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso12" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color12"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoRomero} className="justify-content-end premio-img animate__animated hideEl" id="premio12"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso13" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color13"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoTradicion} className="justify-content-end premio-img animate__animated hideEl" id="premio13"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso14" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color14"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoAhumado} className="justify-content-end premio-img animate__animated hideEl" id="premio15"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso15" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color15"></div>
                  </div>
               </div>
               <div className="row align-items-center box-queso-premio">
                  <div className="col-lg-6 col-12">
                     <div className="premio-img-box">
                        <img src={PremioQuesoMielOregano} className="justify-content-end premio-img animate__animated hideEl" id="premio16"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <h2 className="tituloQueso"><Trans i18nKey="premio-nombrequeso16" components={{ span: <span /> }} /></h2>
                     <div className="linea-bottom-queso linea-color16"></div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-12">
                     <div className="text-center mt-4">
                        <a href="//vegasotuelamos.com/tienda/44-quesos-premiados" className="btn-comprar" target="_blank"><img className="img-carrito" src={carrito} />&nbsp;<Trans i18nKey="comprar-quesos-premiados" /></a>
                     </div>
                  </div>
               </div>

            </div>
         </div>            
         <div className="premiosValorados">
            <img src={corona} className="corona"/>
            <h1 className="titulo">
               <Trans i18nKey="premios-muy-valorados" components={{ span: <span /> }} />
            </h1>
            <div className="contenedorTexto">
               <p className="texto">
                  <Trans i18nKey="premios-muy-valorados-text" components={{ span: <span /> }} />
               </p>
            </div>
            <div className="premios">
               <div className="premioP">
                  <div className="image">
                     <img src={premio} alt="premio"/>
                  </div>
                  <div className="premiotexto">
                     <p className="cheese"><Trans i18nKey="premios-muy-valorados-tit1" /></p>
                     <p className="texto">
                        <Trans i18nKey="premios-muy-valorados-text1" />
                     </p>
                  </div>
               </div>
               <div className="premioP" style={{ marginBottom: "50px" }}>
                  <div className="image">
                     <img src={premio2} alt="premio" />
                  </div>
                  <div className="premiotexto">
                     <p className="cheese"><Trans i18nKey="premios-muy-valorados-tit2" /></p>
                     <p className="texto">
                        <Trans i18nKey="premios-muy-valorados-text2" />
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default Somos;