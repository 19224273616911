import React, {useState, useEffect, useRef } from "react";
import "../styles/ovejas.scoped.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'animate.css';
import { useTranslation, Trans } from 'react-i18next';

const orca = "/images/ovejas-orca.png";
const oveja = "/images/ovejas-oveja.png";
const molino = "/images/ovejas-molino.png";
const Julieta = "/images/Julieta.png";
const Susana = "/images/Susana.png";
const Carolina = "/images/Carolina.png";
const Lupe = "/images/Lupe.png";
const Carmen = "/images/Carmen.png";
const Teresa = "/images/Teresa.png";

const Somos = () => {
   const { t } = useTranslation(); 

   useEffect(() => {
      // Objeto con IDs de elementos como claves y clases correspondientes como valores
      const tituloQuesoAnimate = 'animate__fadeInLeft';
      const imgQuesoAnimate = 'animate__fadeInRight';
      const textQuesoAnimate = 'animate__fadeInDown';

      const elementosClases = {
         'ganaderos': 'animate__backInDown',
         'titulo-ganaderos': 'animate__fadeInUp',
         'subtitulo-ganaderos': 'animate__fadeInUp',
         'ordeno': 'animate__backInDown',
         'titulo-ordeno': 'animate__fadeInUp',
         'subtitulo-ordeno': 'animate__fadeInUp',
         'economia': 'animate__backInDown',
         'titulo-economia': 'animate__fadeInUp',
         'subtitulo-economia': 'animate__fadeInUp',
         'titulo-julieta': tituloQuesoAnimate,
         'img-julieta': imgQuesoAnimate,
         'text-julieta': textQuesoAnimate,
         'titulo-susana': tituloQuesoAnimate,
         'img-susana': imgQuesoAnimate,
         'text-susana': textQuesoAnimate,
         'titulo-carolina': tituloQuesoAnimate,
         'img-carolina': imgQuesoAnimate,
         'text-carolina': textQuesoAnimate,
         'titulo-lupe': tituloQuesoAnimate,
         'img-lupe': imgQuesoAnimate,
         'text-lupe': textQuesoAnimate,
         'titulo-carmen': tituloQuesoAnimate,
         'img-carmen': imgQuesoAnimate,
         'text-carmen': textQuesoAnimate,
         'titulo-teresa': tituloQuesoAnimate,
         'img-teresa': imgQuesoAnimate,
         'text-teresa': textQuesoAnimate,
      };

      // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
      const agregarClaseSiVisible = () => {
         Object.keys(elementosClases).forEach((id) => {
         const elemento = document.getElementById(id);

         // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
         if (elemento) {
            const elementoPosicion = elemento.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (elementoPosicion < windowHeight) {
               elemento.classList.add(elementosClases[id]);
            } else {
               elemento.classList.remove(elementosClases[id]);
            }
         }
         });
      };

      // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
      document.addEventListener('scroll', agregarClaseSiVisible);

      // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
      return () => {
         document.removeEventListener('scroll', agregarClaseSiVisible);
      };
   }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

   return(
      <div className="ovejas">
         <Header/>
         <div className="espacioHeader"></div>
         <div className="cabeceraBig bgOvejas overlay">
            <div className="cabeceraTituloContenedor">
               <h1 className="cabecera animate__animated animate__fadeIn">
                  <Trans i18nKey="practicas-responsables" components={{ span: <span /> }} />
               </h1>
               <p className="cabeceraSubtitulo animate__animated animate__fadeInUp">
                  <Trans i18nKey="ovejas-libres" />
               </p>
            </div>
         </div>
         <div className="natural">
            <h1 className="titulo">
               <Trans i18nKey="compromiso-natural" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-3 mb-4"></div>
            <div className="images">
               <div className="image">
                  <img src={orca} alt="imagen tecnología" className="animate__animated hideEl" id="ganaderos"/>
                  <p className="titulo animate__animated hideEl" id="titulo-ganaderos">
                     <Trans i18nKey="ganaderos-responsables" />
                  </p>
                  <p className="texto animate__animated hideEl" id="subtitulo-ganaderos">
                     <Trans i18nKey="ganaderos-responsables-text" components={{ b: <b /> }} />
                  </p>
               </div>
               <div className="image">
                  <img src={oveja} alt="imagen tecnología" className="animate__animated hideEl" id="ordeno"/>
                  <p className="titulo animate__animated hideEl" id="titulo-ordeno">
                     <Trans i18nKey="ordeno-respetuoso" />
                  </p>
                  <p className="texto animate__animated hideEl" id="subtitulo-ordeno">
                     <Trans i18nKey="ordeno-respetuoso-text" components={{ b: <b /> }} />
                  </p>
               </div>
               <div className="image">
                  <img src={molino} alt="imagen tecnología" className="animate__animated hideEl" id="economia"/>
                  <p className="titulo animate__animated hideEl" id="titulo-economia">
                     <Trans i18nKey="pro-economia" />
                  </p>
                  <p className="texto animate__animated hideEl" id="subtitulo-economia">
                     <Trans i18nKey="pro-economia-text" components={{ b: <b /> }} />
                  </p>
               </div>
            </div>
         </div>
         <div className="lecheCalidadSabor">
            <h1 className="titulo">
               <Trans i18nKey="ovejas-manchegas" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos"></div>
            <p className="texto">
               <Trans i18nKey="ovejas-manchegas-text" components={{ span: <span /> }} />
            </p>
            <div>
               <div className="box-quesos-oveja">
                  <div className="detalle up-julieta">
                     <p className="textoFondo animate__animated hideEl" id="titulo-julieta"><Trans i18nKey="queso-oveja1" /></p>
                     <img src={Julieta} id="img-julieta" className="animate__animated hideEl"/>
                     <p className="texto animate__animated hideEl" id="text-julieta">
                        <Trans i18nKey="queso-oveja1-text" components={{ b: <b /> }} />
                     </p>
                  </div>
                  <div className="detalle">
                     <p className="textoFondo animate__animated hideEl" id="titulo-susana"><Trans i18nKey="queso-oveja2" /></p>
                     <img src={Susana} className="animate__animated hideEl" id="img-susana"/>
                     <p className="texto animate__animated hideEl" id="text-susana">
                        <Trans i18nKey="queso-oveja2-text" components={{ b: <b /> }} />
                     </p>
                  </div>
                  <div className="detalle">
                     <p className="textoFondo animate__animated hideEl" id="titulo-carolina"><Trans i18nKey="queso-oveja3" /></p>
                     <img src={Carolina} className="animate__animated hideEl" id="img-carolina"/>
                     <p className="texto animate__animated hideEl" id="text-carolina">
                        <Trans i18nKey="queso-oveja3-text" components={{ b: <b /> }} />
                     </p>
                  </div>
                  <div className="detalle">
                     <p className="textoFondo animate__animated hideEl" id="titulo-lupe"><Trans i18nKey="queso-oveja4" /></p>
                     <img src={Lupe} className="animate__animated hideEl" id="img-lupe"/>
                     <p className="texto animate__animated hideEl" id="text-lupe">
                        <Trans i18nKey="queso-oveja4-text" components={{ b: <b /> }} />
                     </p>
                  </div>
                  <div className="detalle">
                     <p className="textoFondo animate__animated hideEl" id="titulo-carmen"><Trans i18nKey="queso-oveja5" /></p>
                     <img src={Carmen} className="animate__animated hideEl" id="img-carmen"/>
                     <p className="texto animate__animated hideEl" id="text-carmen">
                        <Trans i18nKey="queso-oveja5-text" components={{ b: <b /> }} />
                     </p>
                  </div>
                  <div className="detalle">
                     <p className="textoFondo animate__animated hideEl" id="titulo-teresa"><Trans i18nKey="queso-oveja6" /></p>
                     <img src={Teresa} className="animate__animated hideEl" id="img-teresa"/>
                     <p className="texto animate__animated hideEl" id="text-teresa">
                        <Trans i18nKey="queso-oveja6-text" components={{ b: <b /> }} />
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default Somos;