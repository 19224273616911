import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import "../styles/contactForm.css";
import * as Yup from "yup";
import { useTranslation, Trans } from 'react-i18next';

function FormContact(props) {
   const { t } = useTranslation();
   
   const { children } = props;
   const [sending, setSending] = useState(false);
   const [submissionStatus, setSubmissionStatus] = useState(""); // To store success or error message
   const initialValues = {
      email: "",
      mensaje: "",
   };

   const validationSchema = Yup.object().shape({
      nombre: Yup.string().required(t('nombre-obligatorio')),
      email: Yup.string().email(t('email-mal')).required(t('email-obligatorio')),
      mensaje: Yup.string().required(t('mensaje-obligatorio')),
      legal: Yup.boolean().oneOf([true], t('debe-aceptar-politica-privacidad')),
   });

   const submit = async (values, { resetForm }) => {
      try {
         setSending(true); // Update sending state to show the spinner

         // Use the Fetch API to send the form data to the server
         const response = await fetch("/email/email.php", {
            method: "POST",
            headers: {
               "Content-Type": "application/json", // Mantén este como application/json
            },
            body: JSON.stringify(values),
         });

         // Parse the response from the server
         const data = await response.json();

         // Check if the response indicates success or error
         if (response.ok) {
            resetForm();
            setSubmissionStatus(t('form-enviado'));
         } else {
            setSubmissionStatus(t('form-error'));
         }
      } catch (error) {
         console.error(t('form-error-peq'), error);
         setSubmissionStatus(t('form-error'));
      } finally {
         setSending(false); // Reset the sending state
      }
   };
 
  return (
   <section id="form-contact2">
      <div className="grid">
         {children}
         <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema}>
         {({ isSubmitting }) => (
          <Form action="/email/sendEmail.php">
            <Field
              id="nombre"
              className="input-primary"
              name="nombre"
              placeholder={t('form-nombre')}
              type="text"
              required
            />
            <Field
              id="empresa"
              className="input-primary"
              name="empresa"
              placeholder={t('form-empresa')}
              type="text"
            />
            <Field
              id="telefono"
              className="input-primary"
              name="telefono"
              placeholder={t('form-telefono')}
              type="tel"
            />
            <Field
              id="email"
              className="input-primary"
              name="email"
              placeholder={t('form-email')}
              type="email"
              required
            />
            <Field
              id="mensaje"
              name="mensaje"
              className="input-primary"
              placeholder={t('form-mensaje')}
              type="text"
              as="textarea"
              required
            />
            {submissionStatus && <p className="info-contact-submit">{submissionStatus}</p>}
            <div className="contenedorLegalyBoton">
               <p className="input-legal">
               <label>
                  <Field id="legal" name="legal" type="checkbox" required />
                  <Trans i18nKey="acepto-la" /> <Link to="/politica-de-proteccion-de-datos" target="_blank"><Trans i18nKey="politica-de-proteccion" /></Link>.
               </label>
               </p>
               <button type="submit" className="submit-button" id="enviar" disabled={sending}>
               {sending ? <div className="spinner" /> : null}
                  <Trans i18nKey="btn-enviar" />
               </button>
            </div>
            <div>
              <p className="legalCorto">
               <Trans i18nKey="texto-legal" />
              </p>
            </div>
          </Form>
         )}
        </Formik>
      </div>
   </section>
   );
}

export default FormContact;