import React from "react";
import { NavLink } from "react-router-dom";
import { checkWebp } from "../utils/checkWebp";
import FormContact from "./FormContact";
import { useTranslation, Trans } from 'react-i18next';

const logo = checkWebp("/images/footer_logo_vegasotuelamos.svg");
const logoIcono = "/images/vega_sotuelamos_logoS.svg";
const facebook_icon = "/images/icon-facebook.svg";
const instagram_icon = "/images/icon-instagram.svg";
const linkedin_icon = "/images/icon-linkedin.svg";
const CertificadoCalidad1 = checkWebp("/images/CertificadoCalidad1.png");
const CertificadoCalidad2 = checkWebp("/images/CertificadoCalidad2.png");
const CertificadoCalidad3 = checkWebp("/images/CertificadoCalidad3.png");
const CertificadoCalidad4 = checkWebp("/images/CertificadoCalidad4.png");
const CertificadoCalidad5 = checkWebp("/images/CertificadoCalidad5.png");
const CertificadoCalidad6 = checkWebp("/images/CertificadoCalidad6.png");
const CertificadoCalidad7 = checkWebp("/images/CertificadoCalidad7.png");
const CertificadoCalidad8 = checkWebp("/images/CertificadoCalidad8.png");
const CertificadoCalidad9 = checkWebp("/images/CertificadoCalidad9.png");
const CertificadoCalidad10 = checkWebp("/images/CertificadoCalidad10.png");
const CertificadoCalidad11 = checkWebp("/images/CertificadoCalidad11.png");
const CertificadoCalidad12 = checkWebp("/images/CertificadoCalidad12.png");
const CertificadoCalidad13 = checkWebp("/images/CertificadoCalidad13.png");
const ueLogo = "/images/ue-logo.png";

const Footer = () => {
   const { t } = useTranslation();
   
   return(
      <div className="footer">
         <div className="row">
            <div className="col-lg-3 col-md-12">
               <p className="footerTituloNaranja"><Trans i18nKey="estamos-en" /></p>
               <p className="footerTextoNaranja"><Trans i18nKey="maduracion-logistica" /><br/><span className="footerTextoBlanco">Pol. Ind. Camporrosso. <br/>C/ Ciudad Real, 3 - 02520 Chinchilla de Montearagón, Albacete</span></p>
               <p className="footerTextoNaranja"><Trans i18nKey="fabrica" /><br/><span className="footerTextoBlanco">Finca Sotuelamos s/n 02610<br/>El Bonillo, Albacete</span></p>
            </div>
            <div className="col-lg-3 col-md-12">
               <p className="footerTituloNaranja"><Trans i18nKey="legales" /></p>
               <p><NavLink to="/aviso-legal" className="footerTextoBlanco"><Trans i18nKey="aviso-legal" /></NavLink></p>
               <p><NavLink to="/politica-de-proteccion-de-datos" className="footerTextoBlanco"><Trans i18nKey="proteccion-datos" /></NavLink></p>
               <p><NavLink to="/cookies" className="footerTextoBlanco"><Trans i18nKey="politica-cookies" /></NavLink></p>
               <p><NavLink to="/politica-de-calidad" className="footerTextoBlanco"><Trans i18nKey="politica-calidad-titulo" /></NavLink></p>
            </div>
            <div className="col-lg-6 col-md-12">
               <p className="footerTextoBlanco"><Trans i18nKey="quieres-ser-distribuidor" components={{ span: <span /> }} /></p>
               <FormContact/>
            </div>
         </div>
         <hr className="lineaBlanca"></hr>
         <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4 text-center">
               <img src={logo} className="footerBloque21" alt="logo"/>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
               <p className="footerTextoBlanco text-justify"><Trans i18nKey="footer-texto-blanco" /></p>
            </div>
            <div className="col-lg-6 col-md-12 text-center text-sm-start">
               <p className="footerTituloNaranja"><Trans i18nKey="certificados-calidad" /></p>
               <div>
                  {/* <img src={CertificadoCalidad1} alt="CertificadoCalidad" className="CertificadoCalidad"/> */}
                  <img src={CertificadoCalidad2} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad3} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad4} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad5} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad6} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  {/* <img src={CertificadoCalidad7} alt="CertificadoCalidad" className="CertificadoCalidad"/> */}
                  <img src={CertificadoCalidad8} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  {/* <img src={CertificadoCalidad9} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad10} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad11} alt="CertificadoCalidad" className="CertificadoCalidad"/> */}
                  <img src={CertificadoCalidad12} alt="CertificadoCalidad" className="CertificadoCalidad"/>
                  <img src={CertificadoCalidad13} alt="CertificadoCalidad" className="CertificadoCalidad"/>
               </div>
            </div>
         </div>
         <hr className="lineaBlanca"></hr>
         <div className="row">
            <div className="col-lg-4 col-md-12">
               <div className="ue-logo">
                  <a href="/files/Fondo-Europeo-Agricola-de-Desarrollo-Rural.pdf" target="_blank">
                     <img src={ueLogo} alt="UE logo"/>
                  </a>
               </div>
            </div>
            <div className="footerBloque31 col-lg-4 col-md-6 col-sm-12">
               <img src={logoIcono} alt="logo"/>
               <p className="footerTextoBlanco mt-3">Copyright© 2023 <a href="https://lagahe.com/" target="_blank" className="footerTextoBlanco"><Trans i18nKey="diseno-web" /></a></p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 iconosFooter">
               <div className="iconos-sociales">
                  <a href="https://www.instagram.com/vegasotuelamos" target="_blank">
                     <div className="icono-instagram">
                        <img src={instagram_icon} alt="icono instagram"/>
                     </div>
                  </a>
                  <a href="https://www.facebook.com/VegaSotuelamos" target="_blank">
                     <div className="icono-facebook">
                        <img src={facebook_icon} alt="icono facebook"/>
                     </div>
                  </a>
                  <a href="https://www.linkedin.com/company/quesosvegasotuelamos" target="_blank">
                     <div className="icono-instagram">
                        <img src={linkedin_icon} alt="icono linkedin"/>
                     </div>
                  </a>
               </div>
               <NavLink to="https://www.vegasotuelamos.com/blog/" target="_blank" className="enlaceFooter enlaceFooterBorder"><Trans i18nKey="BLOG" /></NavLink>
               <NavLink to="/prensa" className="enlaceFooter"><Trans i18nKey="PRENSA" /></NavLink>
            </div>
         </div>
      </div>
   )
};

export default Footer;