import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import "../../styles/subcategorias.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion";

const principal = "/images/Cunas-quesos-receta-exclusiva.png";

// imagenes cuñas quesos
const queso1 = "/images/queso-oveja-ahumado-cuna.png";
const queso2 = "/images/queso-oveja-anchoa-cuna.png";
const queso3 = "/images/queso-oveja-boletus-cuna.png";
const queso4 = "/images/queso-oveja-aceitunan-cuna.png";
const queso5 = "/images/queso-oveja-ajon-cuna.png";
const queso6 = "/images/queso-oveja-trufan-cuna.png";
const queso7 = "/images/queso-oveja-trufab-cuna.png";
const queso8 = "/images/queso-oveja-cruda-trufan-cuna.png";
const queso9 = "/images/queso-oveja-diablo-cuna.png";
const queso10 = "/images/queso-oveja-jalapeno-cuna.png";
const queso11 = "/images/queso-oveja-infierno-cuna.png";
const queso12 = "/images/queso-oveja-mieloregano-cuna.png";
const queso13 = "/images/queso-oveja-romero-cuna.png";
const queso14 = "/images/queso-oveja-tradicion-cuna.png";

// imagenes sliders
const fondo1a = "/images/Queso-de-oveja-AHUMADO-1.png";
const fondo1b = "/images/Queso-de-oveja-AHUMADO-2.png";
const fondo2a = "/images/Queso-curado-de-oveja-con-ANCHOA-1.png";
const fondo2b = "/images/Queso-curado-de-oveja-con-ANCHOA-2.png";
const fondo3a = "/images/Queso-curado-de-oveja-con-BOLETUS-1.png";
const fondo3b = "/images/Queso-curado-de-oveja-con-BOLETUS-2.png";
const fondo4a = "/images/Queso-curado-de-oveja-con-ACEITUNA-NEGRA-1.png";
const fondo4b = "/images/Queso-curado-de-oveja-con-ACEITUNA-NEGRA-2.png";
const fondo5a = "/images/Queso-curado-de-oveja-con-AJO-NEGRO-1.png";
const fondo5b = "/images/Queso-curado-de-oveja-con-AJO-NEGRO-2.png";
const fondo6a = "/images/Queso-curado-de-oveja-con-TRUFA-NEGRA-1.png";
const fondo6b = "/images/Queso-curado-de-oveja-con-TRUFA-NEGRA-2.png";
const fondo7a = "/images/Queso-curado-de-oveja-con-TRUFA-BLANCA-1.png";
const fondo7b = "/images/Queso-curado-de-oveja-con-TRUFA-BLANCA-2.png";
const fondo8a = "/images/Queso-curado-de-oveja-de-leche-cruda-con-TRUFA-NEGRA-1.png";
const fondo8b = "/images/Queso-curado-de-oveja-de-leche-cruda-con-TRUFA-NEGRA-2.png";
const fondo9a = "/images/Queso-curado-de-oveja-con-DIABLO-1.png";
const fondo9b = "/images/Queso-curado-de-oveja-con-DIABLO-2.png";
const fondo10a = "/images/Queso-curado-de-oveja-con-JALAPENOS-1.png";
const fondo10b = "/images/Queso-curado-de-oveja-con-JALAPENOS-2.png";
const fondo11a = "/images/Queso-curado-de-oveja-con-INFIERNO-1.png";
const fondo11b = "/images/Queso-curado-de-oveja-con-INFIERNO-2.png";
const fondo12a = "/images/Queso-curado-de-oveja-cubierto-de-MIEL-y-OREGANO-1.png";
const fondo12b = "/images/Queso-curado-de-oveja-cubierto-de-MIEL-y-OREGANO-2.png";
const fondo13a = "/images/Queso-anejo-de-oveja-al-ROMERO-1.png";
const fondo13b = "/images/Queso-anejo-de-oveja-al-ROMERO-2.png";
const fondo14a = "/images/Queso-anejo-de-oveja-TRADICION-1.png";
const fondo14b = "/images/Queso-anejo-de-oveja-TRADICION-2.png";

// añade clases animate.css al hacer click para ir al producto
const commonClasses = {
   "circuloContenedor": "animate__bounceOut",
   "svg-circulo": "animate__bounceOutRight",
   "cunaQueso": "animate__bounceOutLeft",
   "tiempoMaduracion": "animate__bounceOutUp",
   "tituloQueso": "animate__bounceOutUp",
   "slider": "animate__fadeOutDown",
 };
 
 const initialItems = {
   "queso-oveja-ahumado-cuna": {
     classes: { ...commonClasses },
     url: "/queso-oveja-ahumado",
   },
   "queso-oveja-anchoa-cuna": {
     classes: { ...commonClasses },
     url: "/queso-oveja-anchoa",
   },
   "queso-oveja-boletus-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-boletus",
   },
   "queso-oveja-aceitunan-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-aceituna-negra",
   },
   "queso-oveja-ajon-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-ajo-negro",
   },
   "queso-oveja-trufan-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-trufa-negra",
   },
   "queso-oveja-trufab-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-trufa-blanca",
   },
   "queso-oveja-cruda-trufan-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-leche-cruda-trufa-negra",
   },
   "queso-oveja-diablo-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-diablo",
   },
   "queso-oveja-jalapeno-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-jalapenos",
   },
   "queso-oveja-infierno-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-infierno",
   },
   "queso-oveja-mieloregano-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-miel-oregano",
   },
   "queso-oveja-romero-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-romero",
   },
   "queso-oveja-tradicion-cuna": {
      classes: { ...commonClasses },
      url: "/queso-oveja-tradicion",
   },
 };
 
 const handleCircleClick = (id) => {
  const item = initialItems[id];

  if(!isMobile && !isTablet) {
     // Seleccionar el contenedor específico por ID
     const targetElement = document.getElementById(id);
  
     for (const elementClass in item.classes) {
        const element = targetElement.querySelector(`.${elementClass}`);
     
        if (element) {
           element.classList.add(item.classes[elementClass]);
        }
     }
  
     // Aplicar la transición de opacidad
     targetElement.style.transition = 'opacity 700ms ease-in-out';
     targetElement.style.opacity = '0';

     setTimeout(() => {
       for (const elementClass in item.classes) {
         const element = targetElement.querySelector(`.${elementClass}`);
      
         if (element) {
           targetElement.style.opacity = '1';
           element.classList.remove(item.classes[elementClass]);
         }
       }
        window.location.href = item.url;
     }, 700);
  } else {
     window.location.href = item.url;
  }
};
// fin añade clases animate.css al hacer click para ir al producto

const Categorias = () => {
   const { t } = useTranslation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   }, []);
  
  const [moveup, setMoveup] = React.useState(false);

  return (
    <div>
      <Header/>
      <div className="section">
        <div className="principal gradiente-quesos-oveja-recetas">
          <h1 className="Titulo">
            <Trans i18nKey="tit-cat-recetas" components={{ span: <span /> }} />
          </h1>
          <div className="lineaRoja"></div>
          <p className="TextoBlanco">
            <Trans i18nKey="text-cat-recetas" components={{ b: <b /> }} />
          </p>
          <img
            src={principal}
            className="img-portada"
            alt="Foto cuatro cuñas de queso"
          />
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient17" id="queso-oveja-ahumado-cuna">
          <p className="tiempoMaduracion text-white animate__animated">20 - 30 <Trans i18nKey="dias" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-ahumado" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso1} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo circuloBlanco text-white"
                onClick={() => handleCircleClick("queso-oveja-ahumado-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient18" id="queso-oveja-anchoa-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-anchoa" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso2} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo circuloBlanco text-white"
                onClick={() => handleCircleClick("queso-oveja-anchoa-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient19" id="queso-oveja-boletus-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-boletus" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso3} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-oveja-boletus-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient20" id="queso-oveja-aceitunan-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-aceituna" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso4} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-aceitunan-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient21" id="queso-oveja-ajon-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-ajo-negro" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso5} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-ajon-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient22" id="queso-oveja-trufan-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-trufa-negra" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso6} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-trufan-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient23" id="queso-oveja-trufab-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-trufa-blanca" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso7} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-trufab-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
                <img src={fondo7a} className="imagenSlider" />
                <img src={fondo7b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient24" id="queso-oveja-cruda-trufan-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-cruda-trufa-negra" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso8} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-cruda-trufan-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
                <img src={fondo8a} className="imagenSlider" />
                <img src={fondo8b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient25" id="queso-oveja-diablo-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-diablo" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso9} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-diablo-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient26" id="queso-oveja-jalapeno-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-jalapenos" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso10} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-jalapeno-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
                <img src={fondo10a} className="imagenSlider" />
                <img src={fondo10b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient27" id="queso-oveja-infierno-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-infierno" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso11} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-infierno-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
                <img src={fondo11a} className="imagenSlider" />
                <img src={fondo11b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient28" id="queso-oveja-mieloregano-cuna">
          <p className="tiempoMaduracion text-white animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-miel-oregano" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso12} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-mieloregano-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient29" id="queso-oveja-romero-cuna">
          <p className="tiempoMaduracion text-white animate__animated">12 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-romero" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso13} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-romero-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient30" id="queso-oveja-tradicion-cuna">
          <p className="tiempoMaduracion text-white animate__animated">12 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-recetas-tradicion" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso14} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESOS DE SABORES - QUESOS DE SABORES -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-oveja-tradicion-cuna")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
                <img src={fondo14a} className="imagenSlider" />
                <img src={fondo14b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Categorias;