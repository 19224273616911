import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import "../../styles/subcategorias.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion";

const principal = "/images/Cunas-quesos-oveja.png";

// imagenes cuñas quesos
const queso1 = "/images/queso-oveja-semicurado-cuna.png";
const queso2 = "/images/queso-oveja-curado-cuna.png";
const queso3 = "/images/queso-oveja-tierno-cuna.png";
const queso4 = "/images/queso-oveja-trufan-cuna.png";
const queso5 = "/images/queso-oveja-anejo-cuna.png";

// imagenes sliders
const fondo1a = "/images/Queso-TIERNO-de-leche-cruda-de-oveja-1.png";
const fondo1b = "/images/Queso-TIERNO-de-leche-cruda-de-oveja-2.png";
const fondo2a = "/images/Queso-SEMICURADO-de-leche-cruda-de-oveja-1.png";
const fondo2b = "/images/Queso-SEMICURADO-de-leche-cruda-de-oveja-2.png";
const fondo3a = "/images/Queso-CURADO-de-leche-cruda-de-oveja-1.png";
const fondo3b = "/images/Queso-CURADO-de-leche-cruda-de-oveja-2.png";
const fondo4a = "/images/Queso-ANEJO-de-leche-cruda-de-oveja-1.png";
const fondo4b = "/images/Queso-ANEJO-de-leche-cruda-de-oveja-2.png";

// añade clases animate.css al hacer click para ir al producto
const commonClasses = {
   "circuloContenedor": "animate__bounceOut",
   "svg-circulo": "animate__bounceOutRight",
   "cunaQueso": "animate__bounceOutLeft",
   "tiempoMaduracion": "animate__bounceOutUp",
   "tituloQueso": "animate__bounceOutUp",
   "slider": "animate__fadeOutDown",
 };
 
 const initialItems = {
   "queso-tierno": {
     classes: { ...commonClasses },
     url: "/queso-oveja-tierno",
   },
   "queso-semicurado": {
     classes: { ...commonClasses },
     url: "/queso-oveja-semicurado",
   },
   "queso-curado": {
      classes: { ...commonClasses },
      url: "/queso-oveja-curado",
   },
   "queso-anejo": {
      classes: { ...commonClasses },
      url: "/queso-oveja-anejo",
   },
   "queso-trufa": {
      classes: { ...commonClasses },
      url: "/queso-oveja-trufa-negra",
   },
 };
 
 const handleCircleClick = (id) => {
  const item = initialItems[id];

  if(!isMobile && !isTablet) {
     // Seleccionar el contenedor específico por ID
     const targetElement = document.getElementById(id);
  
     for (const elementClass in item.classes) {
        const element = targetElement.querySelector(`.${elementClass}`);
     
        if (element) {
           element.classList.add(item.classes[elementClass]);
        }
     }
  
     // Aplicar la transición de opacidad
     targetElement.style.transition = 'opacity 700ms ease-in-out';
     targetElement.style.opacity = '0';

     setTimeout(() => {
       for (const elementClass in item.classes) {
         const element = targetElement.querySelector(`.${elementClass}`);
      
         if (element) {
           targetElement.style.opacity = '1';
           element.classList.remove(item.classes[elementClass]);
         }
       }
        window.location.href = item.url;
     }, 700);
  } else {
     window.location.href = item.url;
  }
};
// fin añade clases animate.css al hacer click para ir al producto

const Categorias = () => {
   const { t } = useTranslation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   }, []);
  
  const [moveup, setMoveup] = React.useState(false);

  return (
    <div>
      <Header/>
      <div className="section">
        <div className="principal gradiente-quesos-oveja">
          <h1 className="Titulo">
            <Trans i18nKey="tit-cat-quesos-oveja" components={{ span: <span /> }} />
          </h1>
          <div className="lineaRoja"></div>
          <p className="TextoBlanco">
            <Trans i18nKey="text-cat-quesos-oveja" components={{ b: <b /> }} />
          </p>
          <img
            src={principal}
            className="img-portada"
            alt="Foto cuatro cuñas de queso"
          />
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient1" id="queso-tierno">
          <p className="tiempoMaduracion tiempoMaduracionNegro animate__animated">2 - 3 <Trans i18nKey="semanas" /></p>
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-tierno-leche-oveja" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso3} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-tierno")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient2" id="queso-semicurado">
          <p className="tiempoMaduracion tiempoMaduracionNegro animate__animated">2 - 3 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-semicurado-leche-oveja" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso1} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-semicurado")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
                <img src={fondo2a} className="imagenSlider" />
                <img src={fondo2b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient3" id="queso-curado">
          <p className="tiempoMaduracion tiempoMaduracionNegro animate__animated">5 - 6 <Trans i18nKey="meses" /></p>
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-curado-leche-oveja" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso2} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-curado")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
                <img src={fondo3a} className="imagenSlider" />
                <img src={fondo3b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient4" id="queso-anejo">
          <p className="tiempoMaduracion text-white animate__animated">
            9 - 12 <Trans i18nKey="meses" />
          </p>
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-anejo-leche-oveja" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso5} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo text-white circuloBlanco"
                onClick={() => handleCircleClick("queso-anejo")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
                <img src={fondo4a} className="imagenSlider" />
                <img src={fondo4b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Categorias;
