
export const checkWebp = (path) => {
   var elem = document.createElement('canvas');
   if (!!(elem.getContext && elem.getContext('2d')))
   {
        if(path.includes(".png") || 
             path.includes(".jpg")){
             let newPath = path + ".webp";
             return newPath;
        }

        if(path.includes(".mp4")){
             let newPath = path + ".webm";
             return newPath;
        }
   }
   return path;
}