import React, {useState, useEffect } from "react";
import "../../styles/QuesoOveja.scoped.css";
// eslint-disable-next-line
import ReactFullpage from '@fullpage/react-fullpage';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { move } from "formik";
import { useTranslation, Trans } from 'react-i18next';

const cuna = "/images/queso-oveja-semicurado-cuna.png";
const rueda = "/images/interna-queso-crema-de-oveja-ahumado-tarrina-vegasoteulamos.png";
const fondoTiernoLeche = "/images/queso-oveja-tierno-fondo-leche.png";
const fondoTiernoQueso = "/images/queso-oveja-tierno-fondo-queso.png";
const premio = "/images/premio-silver-award.png";
const iconoTarrina = "/images/icono-tarrina.svg";
const iconoIngredientes = "/images/icono-ingredientes.svg";
const iconoAlergenos = "/images/icono-alergenos.svg";
const iconoNotaCata = "/images/icono-nota-cata.svg";
const quesoMedio = "/images/VEGASOTUELAMOS-Ahumado-Crema-03.png";
const iconoMaridaje = "/images/icono-ideas-maridaje.svg";
const icono_carrito = "/images/carrito-compra.svg";

const QuesoOvejaTierno = () => {
   const { t } = useTranslation(); 
   
   return(
      <div id="producto-queso-crema">
         <Header/>
         <div className="espacioHeader"></div>
         <div className="PrincipalProducto queso_product">
            <div style={{ width:"100%" }}>
               <div style={{ display:"flex",justifyContent: "space-between" }}>
                  <img src={fondoTiernoLeche}/>
                  <img className="ocultarmovil" src={fondoTiernoLeche}/>
               </div>
               <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <img src={fondoTiernoQueso}/>
                  <img src={fondoTiernoQueso}/>
               </div>
               <div className="info_producto">
                  <div className="TextoProducto animate__animated animate__fadeInDown">
                     <p className="supertextoProducto"><Trans i18nKey="nombre-producto-queso-crema" /></p>
                     <h1 className="tituloProducto titulo-producto-queso-crema-ahumado"><Trans i18nKey="nombre-producto-crema-ahumado" /></h1>
                  </div>
                  <div className="center">
                     <img src={rueda} className="queso animate__animated animate__bounceInDown"/>
                     <svg xmlns="http://www.w3.org/2000/svg" xmlLang="es" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500"
                        className="textoVueltas animate__animated animate__bounceInRight"
                     >
                        <defs>
                           <path id="textcircle" d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                           transform="rotate (12,250,250)"/>
                        </defs>
                        <g className="textcircle">
                           <text textLength={900}>
                              <textPath xlinkHref="#textcircle" textLength={900}>
                                 QUESO DE OVEJA - QUESO DE OVEJA -
                              </textPath>
                           </text>
                        </g>
                     </svg>
                  </div>
               </div>
               <div className="btn-comprar-producto-box center animate__animated animate__backInUp">
                  <a href="https://www.vegasotuelamos.com/tienda/cremas-de-queso/132-crema-de-queso-de-oveja-ahumado.html" target="_blank">
                     <button class="boton-compra">
                        <img src={icono_carrito} className="icono-carrito" />
                        <span class="comprar-texto"><img src={icono_carrito} className="icono-carrito-hover" /> <Trans i18nKey="comprar-tienda" /></span>
                     </button>
                  </a>
               </div>
            </div>
         </div>
         <div className="datosQueso animate__animated animate__fadeInUp">
            <div className="formatos">
               <p className="formatoTitulo"><Trans i18nKey="producto-formatos" /></p>
               <div className="formatosIconos">
                  <div className="formatoIcono">
                     <img src={iconoTarrina}/>
                     <p className="formatoTextoIcono"><Trans i18nKey="tarrina" /> 125G</p>
                  </div>
               </div>
               {/* <p className="formatoTexto"><Trans i18nKey="producto-curacion" />: 8 <Trans i18nKey="producto-meses" /></p> */}
            </div>
            <div className="row w-75 mx-auto infoQueso">
               <div className="col-lg-6 col-md-12">
                  <div className="infoQuesoBloque">
                     <img src={iconoIngredientes}/>
                     <div className="w-75 mx-auto">
                        <p className="ps-3 infoQuesoTitulo"><Trans i18nKey="producto-ingredientes" /></p>
                        <p className="text-left ps-3">
                           <Trans i18nKey="queso-crema-ahumado-ingredientes" />
                        </p>
                     </div>
                  </div>
                  <div className="infoQuesoBloque">
                     <img src={iconoAlergenos}/>
                     <div className="w-75 mx-auto">
                        <p className="ps-3 infoQuesoTitulo"><Trans i18nKey="producto-alergenos" /></p>
                        <p className="text-left ps-3">
                           <Trans i18nKey="queso-crema-ahumado-alergenos" />
                        </p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6 col-md-12">
                  <div className="infoQuesoBloque">
                     <img src={iconoNotaCata}/>
                     <div className="w-75 mx-auto">
                        <p className="ps-3 infoQuesoTitulo"><Trans i18nKey="producto-nota-cata" /></p>
                        <p className="text-left ps-3">
                           <Trans i18nKey="queso-crema-ahumado-nota-cata" />
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <hr className="lineaInfoQueso linea-queso-crema-ahumado" />
         </div>
         <div className="datosQueso2">
            <div className="row mx-auto w-75 info-section2">
               <div className="col-lg-6 divQuesoMedio">
                  <img src={quesoMedio} className="quesoMedio"/>
               </div>
               <div className="col-lg-6 d-flex align-items-center">
                  <ul className="listaQueso lista-queso-crema-ahumado">
                     <Trans i18nKey="queso-crema-ahumado-items" components={{ li: <li />, b: <b /> }}  />
                  </ul>
               </div>
            </div>
            <div className="mx-auto w-50 ideasMaridaje">
               <div>
                  <img src={iconoMaridaje}/>
               </div>
               <div className="ideasMaridajeTexts">
                  <p className="ideasMaridajeTitulo"><Trans i18nKey="producto-ideas-maridaje" /></p>
                  <p><Trans i18nKey="queso-crema-ahumado-maridaje" /></p>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default QuesoOvejaTierno;