import React, {useState, useEffect, useRef } from "react";
import "../styles/home.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import { useTranslation, Trans } from 'react-i18next';

const innovar = "/images/home_innovar.png";
const autenticidad = "/images/home_autenticidad.png";
const sensacion = "/images/home_sensacion.png";
const intuicion = "/images/home_intuicion.png";
const calidad = "/images/home_queso_calidad.png";
const diferenciacion = "/images/home_queso_diferenciacion.png";
const innovacion = "/images/home_queso_innovacion.png";
const compromiso = "/images/home_queso_compromiso.png";
const quesoAnchoa = "/images/queso-oveja-anchoa-cuna.png";
const quesoInfierno = "/images/queso-oveja-infierno-cuna.png";
const quesoOreganoMiel = "/images/queso-oveja-mieloregano-cuna.png";
const peana = "/images/home_premios_peana.png";
const premio1 = "/images/home_premio1.png";
const premio2 = "/images/home_premio2.png";
const premio3 = "/images/home_premio3.png";
const premio4 = "/images/home_premio4.png";
const premio5 = "/images/home_premio5.png";
const premio6 = "/images/home_premio6.png";
const premio7 = "/images/home_premio7.png";
const premio8 = "/images/home_premio8.png";
const premio9 = "/images/home_premio9.png";

const queso = "/images/home_queso_elaboracion.png";
const leche = "/images/oveja-gif-1.2.gif";
const coagulacion = "/images/home_coagulacion.png";
const coagulacion2 = "/images/home_coagulacion_front.png";
const brazo1 = "/images/mano-gif-1-2.gif";
const brazo2 = "/images/mano-gif-1-2.gif";
const colibri1 = "/images/home_elaboracion_colibri1.png";
const colibri2 = "/images/home_elaboracion_colibri2.png";
const reloj1 = "/images/home_elaboracion_reloj1.png";
const reloj2 = "/images/home_elaboracion_reloj2.png";
const piernas = "/images/home_elaboracion_piernas.png";

const Home = () => {
   const { t } = useTranslation(); 
   
   // Elaboración
   const ref = useRef();
   const [isHovered1, setIsHovered1] = useState(false);
   const [isHovered2, setIsHovered2] = useState(false);
   const [isHovered3, setIsHovered3] = useState(false);
   const [isHovered4, setIsHovered4] = useState(false);
   const [isHovered5, setIsHovered5] = useState(false);

   const [isSelected1, setIsSelected1] = useState(true);
   const [isSelected2, setIsSelected2] = useState(false);
   const [isSelected3, setIsSelected3] = useState(false);
   const [isSelected4, setIsSelected4] = useState(false);
   const [isSelected5, setIsSelected5] = useState(false);

   const selected1 = () => {
      setIsSelected1(true);
      setIsSelected2(false);
      setIsSelected3(false);
      setIsSelected4(false);
      setIsSelected5(false);
      ref.current.scrollTo(0);
   };

   const selected2 = () => {
      setIsSelected1(false);
      setIsSelected2(true);
      setIsSelected3(false);
      setIsSelected4(false);
      setIsSelected5(false);
      ref.current.scrollTo(1);
   };

   const selected3 = () => {
      setIsSelected1(false);
      setIsSelected2(false);
      setIsSelected3(true);
      setIsSelected4(false);
      setIsSelected5(false);
      ref.current.scrollTo(2);
   };

   const selected4 = () => {
      setIsSelected1(false);
      setIsSelected2(false);
      setIsSelected3(false);
      setIsSelected4(true);
      setIsSelected5(false);
      ref.current.scrollTo(3);
   };

   const selected5 = () => {
      setIsSelected1(false);
      setIsSelected2(false);
      setIsSelected3(false);
      setIsSelected4(false);
      setIsSelected5(true);
      ref.current.scrollTo(4);
   };

   const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      speed: 500,
      responsive: [
         {
           breakpoint: 1024, // Punto de quiebre para tablet
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1
           }
         },
         {
           breakpoint: 768, // Punto de quiebre para dispositivos móviles
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1
           }
         }
      ]
   };

   const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
           breakpoint: 1024, // Punto de quiebre para tablet
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1
           }
         },
         {
           breakpoint: 768, // Punto de quiebre para dispositivos móviles
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1
           }
         }
       ]
    };

   const [showCalidad, setshowCalidad] = React.useState(false);
   const [showDiferenciacion, setshowDiferenciacion] = React.useState(false);
   const [showInnovacion, setshowInnovacion] = React.useState(false);
   const [showCompromiso, setshowCompromiso] = React.useState(false);
   
   useEffect(() => {
      AOS.init();
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      // Objeto con IDs de elementos como claves y clases correspondientes como valores
      const elementosClases = {
         titulo_cunaqueso1: 'animate__backInRight',
         titulo_cunaqueso2: 'animate__backInLeft',
         titulo_cunaqueso3: 'animate__backInRight',
         titulo_cunaqueso4: 'animate__backInLeft',
         cuna_queso1: 'animate__backInLeft',
         cuna_queso2: 'animate__backInRight',
         cuna_queso3: 'animate__backInLeft',
         cuna_queso4: 'animate__backInRight',
         titulo_leche: 'animate__fadeInDown',
         box_leche: 'animate__fadeInUp',
         titulo_coagulacion: 'animate__fadeInDown',
         piernas: 'animate__fadeIn',
         nubes: 'animate__fadeIn',
         nubes2: 'animate__fadeIn',
         brazoIzquierda: 'animate__fadeInLeft',
         brazoDerecha: 'animate__fadeInRight',
         queso: 'animate__fadeIn',
         titulo_salado: 'animate__fadeInDown',
         // colibri1: 'animate__fadeInLeft',
         queso2: 'animate__fadeInDownBig',
         // colibri2: 'animate__fadeInRight',
         queso3: 'animate__fadeInDownBig',
         reloj_izquierda: 'animate__fadeIn',
         reloj_derecha: 'animate__fadeIn',
      };

      // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
      const agregarClaseSiVisible = () => {
         Object.keys(elementosClases).forEach((id) => {
         const elemento = document.getElementById(id);

         // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
         if (elemento) {
            const elementoPosicion = elemento.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (elementoPosicion < windowHeight) {
               elemento.classList.add(elementosClases[id]);
            } else {
               elemento.classList.remove(elementosClases[id]);
            }
         }
         });
      };

      // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
      document.addEventListener('scroll', agregarClaseSiVisible);

      // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
      return () => {
         document.removeEventListener('scroll', agregarClaseSiVisible);
      };
   }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

   useEffect(() => {
      const quesoOverlay = document.getElementById('queso');
  
      let angle = 0;
      const radius = 10; // Radio del círculo en píxeles
      const centerX = 0; // Coordenada X del centro del círculo (mitad del ancho de la imagen)
      const centerY = 0; // Coordenada Y del centro del círculo (mitad del alto de la imagen)
  
      function animateQueso() {
        angle += 0.02; // Ajustar la velocidad de movimiento
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        quesoOverlay.style.transform = `translate(${x}px, ${y}px)`;
        requestAnimationFrame(animateQueso);
      }
  
      const animationId = requestAnimationFrame(animateQueso);
  
      // Clean up the animation when the component unmounts
      return () => cancelAnimationFrame(animationId);
    }, []);

   useEffect(() => {
      const pendulums = document.querySelectorAll(".pendulum");

      function swingPendulum(pendulum) {
         let angle = 0;
         let direction = 1;

         function animatePendulum() {
            angle += 0.2 * direction; // Ajustamos la velocidad reduciendo el ángulo
            if (angle > 1 || angle < -5) {
            direction *= -1;
            }
            pendulum.style.transform = `rotate(${angle}deg)`;
            requestAnimationFrame(animatePendulum);
         }

         animatePendulum();
      }

      pendulums.forEach(swingPendulum);
    }, []);

    useEffect(() => {
      const nubesOverlay = document.getElementById('nubes');
  
      let angle = 0;
      const radius = 10; // Radio del círculo en píxeles
      const centerX = 0; // Coordenada X del centro del círculo (mitad del ancho de la imagen)
      const centerY = 0; // Coordenada Y del centro del círculo (mitad del alto de la imagen)
  
      function animateNubes() {
        angle += 0.02; // Ajustar la velocidad de movimiento
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        nubesOverlay.style.transform = `translate(${x}px, ${y}px)`;
        requestAnimationFrame(animateNubes);
      }
  
      const animationId = requestAnimationFrame(animateNubes);
  
      // Clean up the animation when the component unmounts
      return () => cancelAnimationFrame(animationId);
    }, []);

    useEffect(() => {
      const nubesOverlay = document.getElementById('nubes2');
  
      let angle = 0;
      const radius = 4; // Radio del círculo en píxeles
      const centerX = 0; // Coordenada X del centro del círculo (mitad del ancho de la imagen)
      const centerY = 0; // Coordenada Y del centro del círculo (mitad del alto de la imagen)
  
      function animateNubes() {
        angle -= 0.02; // Ajustar la velocidad de movimiento
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        nubesOverlay.style.transform = `translate(${x}px, ${y}px)`;
        requestAnimationFrame(animateNubes);
      }
  
      const animationId = requestAnimationFrame(animateNubes);
  
      // Clean up the animation when the component unmounts
      return () => cancelAnimationFrame(animationId);
    }, []);

    useEffect(() => {
      const pendulums2 = document.querySelectorAll(".pendulum2");

      function swingPendulum2(pendulum) {
         let angle = 0;
         let direction = 1;

         function animatePendulum2() {
            angle += 0.2 * direction; // Ajustamos la velocidad reduciendo el ángulo
            if (angle > 10 || angle < -1) {
            direction *= -1;
            }
            pendulum.style.transform = `rotate(${angle}deg)`;
            requestAnimationFrame(animatePendulum2);
         }

         animatePendulum2();
      }

      pendulums2.forEach(swingPendulum2);
    }, []);

   const elaboracionBoxRef = useRef(null);
   const elaboracionControlRef = useRef(null);
   const [prevScrollPos, setPrevScrollPos] = useState(0);

   // Variables ajustables a tu criterio
   const scrollDownToShow = 800; // Cantidad de scroll hacia abajo para mostrar el div
   const scrollUpToShow = 400; // Cantidad de scroll hacia arriba para mostrar el div
   const scrollUpToHide = 1100; // Cantidad de scroll hacia arriba para ocultar el div
   const scrollDownToHide = 1100; // Cantidad de scroll hacia abajo para ocultar el div

   useEffect(() => {
      const handleScroll = () => {
         const boxElement = elaboracionBoxRef.current;
         const controlElement = elaboracionControlRef.current;

         if (!boxElement || !controlElement) return;

         const boxRect = boxElement.getBoundingClientRect();
         const controlRect = controlElement.getBoundingClientRect();

         const boxTop = boxRect.top;
         const boxBottom = boxRect.bottom;

         // Calculate the ranges based on the adjustable variables
         const topRangeToShow = boxTop + scrollDownToShow;
         const topRangeToHide = boxTop - scrollUpToHide;
         const bottomRangeToShow = boxBottom - scrollDownToHide;
         const bottomRangeToHide = boxBottom - scrollUpToShow;

         const isScrollingUp = window.scrollY < prevScrollPos;
         const isVisible =
         topRangeToShow < window.innerHeight && bottomRangeToShow > 0;
         const isHidden =
         (boxTop > window.innerHeight - scrollUpToShow && isScrollingUp) ||
         boxTop < topRangeToHide;

         if (isVisible && !isHidden) {
         controlElement.classList.add("animate__fadeIn");
         controlElement.classList.remove("animate__fadeOut");
         } else {
         controlElement.classList.remove("animate__fadeIn");
         controlElement.classList.add("animate__fadeOut");
         }

         setPrevScrollPos(window.scrollY);
      };

      // Add scroll event listener
      window.addEventListener("scroll", handleScroll);

      // Call handleScroll on mount to check initial visibility
      handleScroll();

      // Clean up: remove event listener on unmount
      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, [prevScrollPos]);

   // efecto scroll proceso elaboracion
   const [activeLink, setActiveLink] = useState(null);
   const [clickedLink, setClickedLink] = useState(null);
   const scrollSpyRefs = useRef([]);

   useEffect(() => {
      const divs = document.querySelectorAll('.scroll-spy-target');
      scrollSpyRefs.current = Array.from(divs);

      const updateActiveLink = () => {
         let currentActiveLink = null;

         for (const div of scrollSpyRefs.current) {
         const rect = div.getBoundingClientRect();
         if (rect.top <= 5 && rect.bottom >= 0) {
            currentActiveLink = `#${div.dataset.target}`;
         }
         }

         setActiveLink(currentActiveLink);
      };

      updateActiveLink();

      const handleScroll = () => {
         updateActiveLink();
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, []);

   useEffect(() => {
      if (clickedLink) {
         setActiveLink(null);
         setTimeout(() => {
         setActiveLink(clickedLink);
         setClickedLink(null);
         }, 1000);
      }
   }, [clickedLink]);

   const handleLinkClick = (event, target) => {
      event.preventDefault();

      const element = document.querySelector(target);
      if (element) {
         const yOffset = -100; // Ajusta este valor para cambiar la cantidad de desplazamiento
         const y =
         element.getBoundingClientRect().top + window.pageYOffset + yOffset;
         window.scrollTo({ top: y, behavior: 'smooth' });

         setClickedLink(target);
      }
   };

   // slider home
   const [currentIndex, setCurrentIndex] = useState(0);
   const images = [sensacion, innovar, autenticidad, intuicion];
   const phrases = [
      [t('llamalo'), t('sensacion')],
      [t('llamalo'), t('innovar')],
      [t('llamalo'), t('autenticidad')],
      [t('llamalo'), t('intuicion')]
   ];

   useEffect(() => {
      // Iniciar el slider automáticamente
      const sliderInterval = setInterval(() => {
         setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4000); // Cambiar cada xxxx milisegundos

      return () => clearInterval(sliderInterval); // Limpiar el intervalo cuando el componente se desmonte
   }, []);
   // fin slider home
   
   const [urlMapping] = useState({
      quesosManchegos: '/quesos-manchegos',
      quesosEcologicos: '/quesos-manchegos-ecologicos',
      quesosLecheCruda: '/quesos-manchegos-leche-cruda',
      quesosOveja: '/quesos-oveja',
      quesosLecheCrudaOveja: '/quesos-oveja-leche-cruda',
      quesosRecetasExclusivas: '/quesos-oveja-recetas',
    });
  
    const handleElementClick = (className) => {
      if (urlMapping[className]) {
        window.location.href = urlMapping[className];
      }
    };
   
   return(
      <div>
         <div>
            <div>
               <div>
                  <Header/>
                  <div className="espacioHeader2"></div>
               </div>
               <div className="elaboracion-control animate__animated hideEl" id="elaboracion-control" ref={elaboracionControlRef}>
                  <div className="points">
                     <a href="#div_proceso" className={`point ${activeLink === '#div_proceso' ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, '#div_proceso')}></a>
                     <a href="#div_coagulacion" className={`point ${activeLink === '#div_coagulacion' ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, '#div_coagulacion')}></a>
                     <a href="#div_salado" className={`point ${activeLink === '#div_salado' ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, '#div_salado')}></a>
                     <a href="#div_secreto" className={`point ${activeLink === '#div_secreto' ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, '#div_secreto')}></a>
                     <a href="#div_maduracion" className={`point ${activeLink === '#div_maduracion' ? 'active' : ''}`} onClick={(e) => handleLinkClick(e, '#div_maduracion')}></a>
                  </div>
               </div>
               <div>
                  {/* <div style={{ height: "85px" }}></div> */}
                  <div className="HomeCarrousel">
                     {images.map((image, index) => (
                     <div
                        key={index}
                        className={`image-container text-center ${currentIndex === index ? 'visible animate__animated animate__fadeIn' : 'hidden animate__animated d-none'}`}
                     >
                        {/* Imagen */}
                        <img src={image} alt={`Imagen ${index + 1}`} />
                        {/* Frases */}
                        <div className={`phrase animate__animated ${currentIndex === index ? 'animate__fadeInUp' : 'd-none'}`}>
                           {phrases[index][0]} <span>{phrases[index][1]}</span>
                        </div>
                     </div>
                     ))}
                  </div>
               </div>
               <div>
                  <div className="quesosOveja">
                     <div className="row"> 
                        <div className="col-lg-3 col-sm-12 col-12"></div>
                        <div className="col-lg-6 col-sm-12 col-12">
                           <h2 className="tituloQuesos">
                              <Trans i18nKey="welcome" components={{ span: <span /> }} />
                           </h2>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-12"></div>
                     </div>
                     <p className="textoQuesos">
                        <Trans i18nKey="textoQuesos" components={{ span: <span /> }} />
                     </p>
                     <div className="lineaRojaQuesos"> 
                     </div>
                     <div className="cajaQuesos">
                        <div className="cajaQuesosSub1">
                           <div className="calidad">
                              <p className="animate__animated hideEl" id="titulo_cunaqueso1" style={{ textAlign: "center", }} onMouseEnter={() => setshowCalidad(true)} onMouseLeave={() => setshowCalidad(false)}><span className="mas circle pulse">+ &nbsp;</span><Trans i18nKey="tit-texto01" /></p>
                              <img src={calidad} alt="queso calidad" className="animate__animated hideEl" id="cuna_queso1"/>
                           </div>
                           <div className="cajaQueso quesoSeleccionado" style={{ display: showCalidad ? 'flex' : 'none' }}>
                              <div onMouseEnter={() => setshowCalidad(true)} onMouseLeave={() => setshowCalidad(false)}>
                                 <p className="titulo"><span className="mas2">+ &nbsp;</span><Trans i18nKey="tit-texto01" /></p>
                                 <p className="texto">
                                    <Trans i18nKey="texto01" components={{ span: <span /> }} />
                                 </p>
                              </div>
                           </div>
                           <div className="diferenciacion">
                              <p className="animate__animated hideEl" id="titulo_cunaqueso2" style={{ textAlign: "center", }} onMouseEnter={() => setshowDiferenciacion(true)} onMouseLeave={() => setshowDiferenciacion(false)}><span className="mas circle pulse">+ &nbsp;</span><Trans i18nKey="tit-texto02" /></p>
                              <img src={diferenciacion} alt="queso calidad" className="animate__animated hideEl" id="cuna_queso2"/>
                           </div>
                           <div className="cajaQueso quesoSeleccionado" style={{ display: showDiferenciacion ? 'flex' : 'none' }}>
                              <div onMouseEnter={() => setshowDiferenciacion(true)} onMouseLeave={() => setshowDiferenciacion(false)}>
                                 <p className="titulo"><span className="mas2">+ &nbsp;</span><Trans i18nKey="tit-texto02" /></p>
                                 <p className="texto">
                                    <Trans i18nKey="texto02" components={{ span: <span /> }} />
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className="cajaQuesosSub2">
                           <div className="innovacion">
                              <p className="animate__animated hideEl" id="titulo_cunaqueso3" style={{ textAlign: "center", }} onMouseEnter={() => setshowInnovacion(true)} onMouseLeave={() => setshowInnovacion(false)}><span className="mas circle pulse">+ &nbsp;</span><Trans i18nKey="tit-texto03" /></p>
                              <img src={innovacion} alt="queso calidad" className="animate__animated hideEl" id="cuna_queso3"/>
                           </div>
                           <div className="cajaQueso quesoSeleccionado" style={{ display: showInnovacion ? 'flex' : 'none' }}>
                              <div onMouseEnter={() => setshowInnovacion(true)} onMouseLeave={() => setshowInnovacion(false)}>
                                 <p className="titulo"><span className="mas2">+ &nbsp;</span><Trans i18nKey="tit-texto03" /></p>
                                 <p className="texto">
                                    <Trans i18nKey="texto03" components={{ span: <span /> }} />
                                 </p>
                              </div>
                           </div>
                           <div className="compromiso">
                              <p className="animate__animated hideEl" id="titulo_cunaqueso4" style={{ textAlign: "center", }} onMouseEnter={() => setshowCompromiso(true)} onMouseLeave={() => setshowCompromiso(false)}><span className="mas circle pulse">+ &nbsp;</span><Trans i18nKey="tit-texto04" /></p>
                              <img src={compromiso} alt="queso calidad" className="animate__animated hideEl" id="cuna_queso4"/>
                           </div>
                           <div className="cajaQueso quesoSeleccionado" style={{ display: showCompromiso ? 'flex' : 'none' }}>
                              <div onMouseEnter={() => setshowCompromiso(true)} onMouseLeave={() => setshowCompromiso(false)}>
                                 <p className="titulo"><span className="mas2">+ &nbsp;</span><Trans i18nKey="tit-texto04" /></p>
                                 <p className="texto">
                                    <Trans i18nKey="texto04" components={{ span: <span /> }} />
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="cajaQuesosMovil">
                        <div className="calidadMovil">
                           <img src={calidad} alt="queso calidad"/>
                           <div>
                              <p className="titulo"><span className="mas2">+ &nbsp;</span> <Trans i18nKey="tit-texto01" /></p>
                              <p className="texto">
                                 <Trans i18nKey="texto01" components={{ span: <span /> }} />
                              </p>
                           </div>
                        </div>
                        <div className="calidadMovil">
                           <img src={diferenciacion} alt="queso calidad"/>
                           <div>
                              <p className="titulo"><span className="mas2">+ &nbsp;</span> <Trans i18nKey="tit-texto02" /></p>
                              <p className="texto">
                                 <Trans i18nKey="texto02" components={{ span: <span /> }} />
                              </p>
                           </div>
                        </div>
                        <div className="calidadMovil">
                           <img src={innovacion} alt="queso calidad"/>
                           <div>
                              <p className="titulo"><span className="mas2">+ &nbsp;</span> <Trans i18nKey="tit-texto03" /></p>
                              <p className="texto">
                                 <Trans i18nKey="texto03" components={{ span: <span /> }} />
                              </p>
                           </div>
                        </div>
                        <div className="calidadMovil">
                           <img src={compromiso} alt="queso calidad"/>
                           <div>
                              <p className="titulo"><span className="mas2">+ &nbsp;</span> <Trans i18nKey="tit-texto04" /></p>
                              <p className="texto">
                                 <Trans i18nKey="texto04" components={{ span: <span /> }} />
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div ref={elaboracionBoxRef} id="elaboracion-box">
                  <div className="seccion-proceso leche scroll-spy-target" id="div_proceso" data-target="div_proceso">
                     <p className="tituloElaboracion"><Trans i18nKey="proceso-elaboracion" components={{ span: <span /> }} /></p>
                     <div className="lineaRojaQuesos"></div>
                     <p className="numPaso1">1.</p>
                     <p className="textoFondo1 animate__animated hideEl" id="titulo_leche"><Trans i18nKey="leche" /></p>
                     <div className="box-leche center animate__animated hideEl" id="box_leche">
                        <img src={leche} alt="imágen leche" className="imagenProcesoLeche"/>
                     </div>
                     <p className="textoDeProceso"><Trans i18nKey="blanco-botella" components={{ b: <b /> }} /></p>
                  </div>
                  <div className="seccion-proceso coagulacion scroll-spy-target" id="div_coagulacion" data-target="div_coagulacion">
                     <p className="numPaso">2.</p>
                     <p className="textoFondo animate__animated hideEl" id="titulo_coagulacion"><Trans i18nKey="coagulacion" /></p>
                     <div className="box-coagulacion center">
                        <div className="text-center">
                           <img src={coagulacion2} alt="coagulacion" className="imagenProcesoCoagulacion2 animate__animated hideEl" id="nubes2" />
                           <img src={piernas} alt="leche" className="imagenPiernas animate__animated hideEl pendulum" id="piernas" />
                           <img src={coagulacion} alt="leche" className="imagenProcesoCoagulacion animate__animated hideEl" id="nubes" />
                        </div>
                     </div>
                     <p className="textoDeProceso"><Trans i18nKey="huelen-nubes" components={{ b: <b /> }} /></p>
                  </div>

                  <div className="seccion-proceso salado scroll-spy-target" id="div_salado" data-target="div_salado">
                     <p className="numPaso">3.</p>
                     <p className="textoFondo animate__animated hideEl" id="titulo_salado"><Trans i18nKey="salado" /></p>
                     <div className="box-salado center">
                        <div className="brazoIzquierda animate__animated hideEl" id="brazoIzquierda">
                           <img src={brazo1} alt="imágen leche"/>
                        </div>
                        <div>
                           <img src={queso} alt="imágen leche" className="imagenProcesoCoagulacion imagenQueso animate__animated" id="queso" />
                        </div>
                        <div className="brazoDerecha animate__animated hideEl" id="brazoDerecha">
                           <img src={brazo2} alt="imágen leche"/>
                        </div>
                     </div>
                     <p className="textoDeProceso"><Trans i18nKey="ciao-suero" components={{ b: <b /> }} /></p>
                  </div>
                  <div className="seccion-proceso secreto scroll-spy-target" id="div_secreto" data-target="div_secreto">
                     <p className="numPaso">4.</p>
                     <p className="textoFondo"><Trans i18nKey="secreto" /></p>
                     <div className="box-secreto center">
                        <div>
                           <img src={colibri1} alt="imágen colibri" className="colibriIzquierda" id="colibri1"/>
                        </div>
                        <div>
                           <img src={queso} alt="imágen leche" className="imagenProcesoCoagulacion imagenQueso animate__animated hideEl" id="queso2" />
                        </div>
                        <div>
                           <img src={colibri2} alt="imágen colibri 2" className="colibriDerecha" id="colibri2"/>
                        </div>
                     </div>
                     <p className="textoDeProceso"><Trans i18nKey="pasa-se-queda" components={{ b: <b /> }} /></p>
                  </div>
                  <div className="seccion-proceso maduracion scroll-spy-target" id="div_maduracion" data-target="div_maduracion">
                     <p className="numPaso">5.</p>
                     <p className="textoFondo"><Trans i18nKey="maduracion" /></p>
                     <div className="box-relojes center">
                        <div>
                           <img src={reloj1} alt="imágen leche" className="relojIzquierda animate__animated hideEl pendulum" id="reloj_izquierda"/>
                        </div>
                        <div>
                           <img src={queso} alt="imágen leche" className="imagenProcesoCoagulacion imagenQueso animate__animated hideEl" id="queso3" />
                        </div>
                        <div>
                           <img src={reloj2} alt="imágen leche" className="relojDerecha animate__animated hideEl pendulum2" id="reloj_derecha"/>
                        </div>
                     </div>
                     <p className="textoDeProceso"><Trans i18nKey="tiempo-cura" components={{ b: <b /> }} /></p>
                  </div>
               </div>

               <div>
                  <div style={{ height: '100%', width: '100px', background: 'transparent', position: 'fixed', right: '0' }}>
                     <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                     <div style={{ width: '10px', height: '10px', margin: '20px 0', cursor: 'pointer', borderRadius: 20, border: '1px solid #fff', background: isSelected1 || isHovered1 ? 'white' : 'transparent'}} onClick={selected1} onMouseOver={() => setIsHovered1(true)} onMouseOut={() => setIsHovered1(false)}/>
                     <div style={{ width: '10px', height: '10px', margin: '20px 0', cursor: 'pointer', borderRadius: 20, border: '1px solid #fff', background: isSelected2 || isHovered2 ? 'white' : 'transparent'}} onClick={selected2} onMouseOver={() => setIsHovered2(true)} onMouseOut={() => setIsHovered2(false)}/>
                     <div style={{ width: '10px', height: '10px', margin: '20px 0', cursor: 'pointer', borderRadius: 20, border: '1px solid #fff', background: isSelected3 || isHovered3 ? 'white' : 'transparent'}} onClick={selected3} onMouseOver={() => setIsHovered3(true)} onMouseOut={() => setIsHovered3(false)}/>
                     <div style={{ width: '10px', height: '10px', margin: '20px 0', cursor: 'pointer', borderRadius: 20, border: '1px solid #fff', background: isSelected4 || isHovered4 ? 'white' : 'transparent'}} onClick={selected4} onMouseOver={() => setIsHovered4(true)} onMouseOut={() => setIsHovered4(false)}/>
                     <div style={{ width: '10px', height: '10px', margin: '20px 0', cursor: 'pointer', borderRadius: 20, border: '1px solid #fff', background: isSelected5 || isHovered5 ? 'white' : 'transparent'}} onClick={selected5} onMouseOver={() => setIsHovered5(true)} onMouseOut={() => setIsHovered5(false)}/>
                     </div>
                  </div>
               </div>

               <div>
                  <div className="carouselQuesos">
                     <h2 className="tituloQuesos">
                        <span className="tituloQuesosResaltado"><Trans i18nKey="novedades" /></span>
                     </h2>
                     <div className="lineaRojaQuesos"></div>
                     <Slider {...settings}>
                        <div>
                           <img src={quesoAnchoa} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio1-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio1-home-texto" /></p>
                           <a href="/queso-oveja-anchoa" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                        <div>
                           <img src={quesoInfierno} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio2-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio2-home-texto" /></p>
                           <a href="/queso-oveja-infierno" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                        <div>
                           <img src={quesoOreganoMiel} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio3-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio3-home-texto" /></p>
                           <a href="/queso-oveja-miel-oregano" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                        <div>
                           <img src={quesoAnchoa} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio4-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio4-home-texto" /></p>
                           <a href="/queso-oveja-anchoa" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                        <div>
                           <img src={quesoInfierno} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio5-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio5-home-texto" /></p>
                           <a href="/queso-oveja-infierno" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                        <div>
                           <img src={quesoOreganoMiel} alt="queso diferenciacion"/>
                           <h3 className="tituloCarouselQuesos"><Trans i18nKey="premio6-home-titulo" components={{ span: <span /> }} /></h3>
                           <p className="textoCarouselQuesos"><Trans i18nKey="premio6-home-texto" /></p>
                           <a href="/queso-oveja-miel-oregano" className="enlaceBotonCarouselQuesos">
                              <Trans i18nKey="btn-descubrelo" />
                           </a>
                        </div>
                     </Slider>
                  </div>
               </div>
               <div>
                  <div className="row catalogoQuesos">
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosManchegos columna" onClick={() => handleElementClick('quesosManchegos')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso1-home" components={{ span: <span /> }} /></p>
                     </div>
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosEcologicos columna" onClick={() => handleElementClick('quesosEcologicos')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso2-home" components={{ span: <span /> }} /></p>
                     </div>
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosLecheCruda columna" onClick={() => handleElementClick('quesosLecheCruda')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso3-home" components={{ span: <span /> }} /></p>
                     </div>
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosOveja columna" onClick={() => handleElementClick('quesosOveja')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso4-home" components={{ span: <span /> }} /></p>
                     </div>
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosLecheCrudaOveja columna" onClick={() => handleElementClick('quesosLecheCrudaOveja')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso5-home" components={{ span: <span /> }} /></p>
                     </div>
                     <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 quesosRecetasExclusivas columna" onClick={() => handleElementClick('quesosRecetasExclusivas')}>
                        <div className="foto"></div>
                        <p><Trans i18nKey="categoria-queso6-home" components={{ span: <span /> }} /></p>
                     </div>
                  </div>
               </div>
               <div>
                  <div className="carouselPremios">
                     <h2 className="tituloPremios"><Trans i18nKey="premiados-internacionalmente" components={{ span: <span /> }} /></h2>
                     <p className="textoFondoPremios"><Trans i18nKey="que-quesos" /></p>
                     <Slider {...settings2} className="sliderCarouselPremios">
                        <div className="premios">
                           <img src={premio1} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio2} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio3} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio4} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio5} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio6} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio7} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio8} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                        <div className="premios">
                           <img src={premio9} alt="premio" className="premio"/>
                           <img src={peana} alt="peana" className="peana"/>
                        </div>
                     </Slider>
                     <div className="botonPremios">
                        <a href="/premios" className="enlaceBotonCarouselPremios">
                           <Trans i18nKey="btn-nuestros-premios" />
                        </a>
                     </div>
                  </div>
               </div>
               <div>
                  <Footer/>
               </div>
            </div>
         </div>
      </div>
   )
};

export default Home;