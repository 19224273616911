import React, { useState, useEffect, useRef } from "react";
import "../styles/contacto.css";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";

const engranajes = "/images/contacto-ruedas.png";
const maletin = "/images/contacto-maletin.png";
const camion = "/images/contacto-camion.png";
const avion = "/images/contacto-avion.png";

const Somos = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    // Objeto con IDs de elementos como claves y clases correspondientes como valores
    const elementosClases = {
      enviar: "animate__bounceInRight",
      "engranajes-animate": "animate__bounceInLeft",
      "maletin-animate": "animate__bounceInLeft",
      "avion-animate": "animate__bounceInLeft",
      "camion-animate": "animate__bounceInLeft",
    };

    // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
    const agregarClaseSiVisible = () => {
      Object.keys(elementosClases).forEach((id) => {
        const elemento = document.getElementById(id);

        // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
        if (elemento) {
          const elementoPosicion = elemento.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;

          if (elementoPosicion < windowHeight) {
            elemento.classList.add(elementosClases[id]);
          } else {
            elemento.classList.remove(elementosClases[id]);
          }
        }
      });
    };

    // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
    document.addEventListener("scroll", agregarClaseSiVisible);

    // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
    return () => {
      document.removeEventListener("scroll", agregarClaseSiVisible);
    };
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

  const { children } = props;
  const [sending, setSending] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(""); // To store success or error message
  const initialValues = {
    email: "",
    mensaje: "",
  };

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required(t("nombre-obligatorio")),
    email: Yup.string().email(t("email-mal")).required(t("email-obligatorio")),
    mensaje: Yup.string().required(t("mensaje-obligatorio")),
    legal: Yup.boolean().oneOf([true], t("debe-aceptar-politica-privacidad")),
  });

  const submit = async (values, { resetForm }) => {
    try {
      setSending(true); // Update sending state to show the spinner

      // Use the Fetch API to send the form data to the server
      const response = await fetch("/email/email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Mantén este como application/json
        },
        body: JSON.stringify(values),
      });

      // Parse the response from the server
      const data = await response.json();

      // Check if the response indicates success or error
      if (response.ok) {
        resetForm();
        setSubmissionStatus(t("form-enviado"));
      } else {
        setSubmissionStatus(t("form-error"));
      }
    } catch (error) {
      console.error(t("form-error-peq"), error);
      setSubmissionStatus(t("form-error"));
    } finally {
      setSending(false); // Reset the sending state
    }
  };

  return (
    <div>
      <Header />
      <div className="espacioHeader"></div>
      <div className="cabeceraBig bgContacto overlay">
        <div className="cabeceraTituloContenedor">
          <h1 className="cabecera animate__animated animate__fadeIn">
            <Trans i18nKey="como-ayudarte" components={{ span: <span /> }} />
          </h1>
          <p className="cabeceraSubtitulo animate__animated animate__fadeInUp">
            <Trans i18nKey="como-ayudarte-text" />
          </p>
        </div>
      </div>
      <div className="formulario">
        <h1 className="titulo">
          <Trans i18nKey="escribenos" />
        </h1>
        <div className="lineaRojaQuesos mt-3 mb-4"></div>
        {children}
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
        >
          <Form className="formularioTag">
            <Field
              id="nombre"
              className="input-primary"
              name="nombre"
              placeholder={t("form-nombre")}
              type="text"
              required
            />
            <Field
              id="empresa"
              className="input-primary"
              name="empresa"
              placeholder={t("form-empresa")}
              type="text"
            />
            <Field
              id="telefono"
              className="input-primary"
              name="telefono"
              placeholder={t("form-telefono")}
              type="tel"
            />
            <Field
              id="email"
              className="input-primary"
              name="email"
              placeholder={t("form-email")}
              type="email"
              required
            />
            <Field
              id="mensaje"
              name="mensaje"
              className="input-primary"
              placeholder={t("form-mensaje")}
              type="text"
              as="textarea"
              required
            />
            {submissionStatus && (
              <p className="info-contact-submit mt-3">{submissionStatus}</p>
            )}
            <div className="contenedorLegalyBoton">
              <p className="input-legal">
                <label>
                  <Field id="legal" name="legal" type="checkbox" required />
                  <Trans i18nKey="acepto-la" />{" "}
                  <Link to="/politica-de-proteccion-de-datos" target="_blank">
                     <Trans i18nKey="politica-de-proteccion" />
                  </Link>
                  .
                </label>
              </p>
              <button
                type="submit"
                className="submit-button animate__animated"
                id="enviar"
              >
                {sending ? <div className="spinner" /> : null}
                <Trans i18nKey="btn-enviar" />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                  <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
            <div>
              <p className="legalCorto">
                <Trans i18nKey="texto-legal" />
              </p>
            </div>
          </Form>
        </Formik>
      </div>
      <div className="datosContacto">
        <h1 className="titulo">
            <Trans i18nKey="visitanos-llamanos" components={{ span: <span /> }} />
        </h1>
        <div className="lineaRojaQuesos"></div>
        <div className="datos">
          <div className="fabrica">
            <div className="parte1">
              <img
                src={engranajes}
                className="animate__animated"
                style={{ width: "400px" }}
                id="engranajes-animate"
              />
            </div>
            <div className="parte2">
              <div>
                <p className="titulo2"><Trans i18nKey="la-fabrica" /></p>
                <p className="texto">
                  Finca Sotuelamos s/n 02610
                  <br />
                  <b>(El Bonillo) Albacete</b> <br />
                  <br />
                  Tel 967 585 017 <br />
                  Fax 967 585 061 <br />
                  <a
                    href="mailto:info@vegasotuelamos.com"
                    className="href-no-style"
                  >
                    info@vegasotuelamos.com
                  </a>
                  <br />
                  <a
                    href="https://goo.gl/maps/TVqufnTvSayLknQT8"
                    target="_blank"
                    className="enlace"
                  >
                     <Trans i18nKey="llegar-fabrica" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="oficinas">
            <div className="parte1">
              <img
                src={maletin}
                className="animate__animated"
                style={{ width: "400px" }}
                id="maletin-animate"
              />
            </div>
            <div className="parte2">
              <div>
                <p className="titulo2"><Trans i18nKey="oficinas" /></p>
                <p className="texto">
                  Pol. Ind. Camporroso C/ Ciudad Real, 3 02520
                  <br />
                  <b>(Chinchilla de Montearagón) Albacete</b>
                  <br />
                  <br />
                  Tel 967 550 376 <br />
                  Fax 967 550 278 <br />
                  <a
                    href="mailto:info@vegasotuelamos.com"
                    className="href-no-style"
                  >
                    info@vegasotuelamos.com
                  </a>
                  <br />
                  <a
                    href="https://goo.gl/maps/CPF2Vahwif3RjGfQ6"
                    target="_blank"
                    className="enlace"
                  >
                     <Trans i18nKey="llegar-oficinas" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="exportacion">
            <div className="parte1">
              <img
                src={avion}
                className="animate__animated"
                style={{ width: "400px" }}
                id="avion-animate"
              />
            </div>
            <div className="d-flex parte2">
              <div>
                <p className="titulo2">
                  <span className="signo">&#62;</span><Trans i18nKey="exportacion" />
                </p>
                <p className="texto">
                  Pol. Ind. Camporroso C/ Ciudad Real, 3 02520
                  <br />
                  <b>(Chinchilla de Montearagón) Albacete</b>
                  <br />
                  <br />
                  Tel 967 550 376 <br />
                  <a
                    href="mailto:info@vegamancha.com"
                    className="href-no-style"
                  >
                    info@vegamancha.com
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="exportacion">
            <div className="parte1">
              <img
                src={camion}
                className="animate__animated"
                style={{ width: "400px" }}
                id="camion-animate"
              />
            </div>
            <div className="d-flex parte2">
              <div>
                <p className="titulo2">
                  <span className="signo">&#62;</span><Trans i18nKey="venta-nacional" />
                </p>
                <p className="texto">
                  Pol. Ind. Camporroso C/ Ciudad Real, 3 02520
                  <br />
                  <b>(Chinchilla de Montearagón) Albacete</b>
                  <br />
                  <br />
                  Tel 967 550 376
                  <br />
                  <a
                    href="mailto:info@vegasotuelamos.com"
                    className="href-no-style"
                  >
                    info@vegasotuelamos.com
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Somos;
