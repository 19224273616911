import React, { useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const Calidad = () => {
   const { t } = useTranslation(); 
   
   return (
      <div>
         <Header />
         <div className="espacioHeader"></div>
         <div className="simple-page" id="politica-calidad">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="politica-calidad-titulo" /></h1>
                     <p><strong>QUESOS VEGA SOTUÉLAMOS, S.L.</strong>, se propone como objetivo alcanzar los más altos niveles en la producción y fabricación de quesos y productos lácteos acordes a las necesidades de sus clientes. Por este motivo, ha implantado un sistema de gestión de seguridad alimentaria basado en los requisitos de los más altos estándares de seguridad alimentaria, legalidad, autenticidad y sostenibilidad en BRCGS e IFS.</p>
                     <p>Considera uno de los pilares más importantes de este proyecto su Política de Calidad, Seguridad Alimentaria y de Medioambiente orientada a conseguir los mejores resultados económicos a través de la Garantía Higiénico-Sanitaria, la Satisfacción de los clientes y de las personas que forman la empresa además de cumplir, simultáneamente, con los compromisos adquiridos con el medioambiente de acuerdo a las certificaciones mencionadas.</p>
                     <p>Para ello, la dirección de <strong>QUESOS VEGA SOTUÉLAMOS, S.L.</strong> con la participación activa de todos sus componentes se compromete a alcanzar los siguientes objetivos generales:</p>
                     <ul>
                        <li>Llevar a cabo la producción y fabricación de quesos y productos lácteos con un ALTO GRADO DE CALIDAD Y SEGURIDAD ALIMENTARIA, que complazca a nuestros clientes y nos diferencie de nuestros competidores, con el interés permanente de evolucionar hacia la mejora continua. Autenticidad de productos; inocuos, legales, y con la autencidad del producto garantizada.</li>
                        <li>Adquirir el compromiso de una CULTURA DE SEGURIDAD ALIMENTARIA sólida en todos los niveles de nuestra empresa, garantizando de este modo nuestro principal objetivo: elaboración de productos de calidad cumpliendo siempre con la seguridad alimentaria.</li>
                        <li>Garantizar el cumplimiento de los requisitos legales que afectan a nuestras actividades, especialmente los dirigidos a asegurar la trazabilidad y las condiciones higiénico sanitarias de nuestros productos, así como los estándares y buenas prácticas aplicables y requisitos de nuestros clientes.</li>
                        <li>Trabajar con el objetivo de perseguir la mejora continua de nuestros procesos de trabajo, asegurando el cumplimiento de nuestra responsabilidad ambiental y nuestra responsabilidad ética y laboral.</li>
                        <li>Cumplir los requisitos Halal establecidos.</li>
                        <li>Selección y homologación de los mejores proveedores.</li>
                        <li>La dirección asume como una obligación propia de sus responsabilidades, el compromiso de ejercer el liderazgo en la implantación y aplicación de conductas acordes con el Sistema de Calidad y Seguridad alimentaria actuando como impulsor, guía y ejemplo en el cumplimiento de las normas establecidas en la empresa.</li>
                        <li>Reducción de los niveles de No Conformidad, así como las quejas y rechazos</li>
                        <li>Favorecer unas condiciones de trabajo adecuadas para conseguir la motivación del personal lo cual redunda en un mejor desarrollo de sus actividades, bajo un entorno de trabajo que asegure la responsabilidad ética y laboral de la Dirección.</li>
                        <li>Mantener nuestro compromiso de mejora continua y de prevención y reducción de IMPACTOS AL MEDIO AMBIENTE en todos los niveles de nuestra empresa.</li>
                        <li>Cumplimiento de los requisitos convenidos con nuestros clientes y consumidores, consolidando su confianza en nuestra organización.</li>
                        <li>La Dirección de <strong>QUESOS VEGA SOTUÉLAMOS, S.L.</strong> apoya esta política en los objetivos relativos a seguridad alimentaria, fijados anualmente en la revisión del sistema.</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
};

export default Calidad;