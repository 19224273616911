import React, {useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const AvisoLegal = () => {
   const { t } = useTranslation(); 
   
   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="simple-page">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="politica-proteccion-datos-titulo" /></h1>
                     <p>QUESOS VEGA SOTUELAMOS, S.L. informa que los datos personales facilitados a trav&eacute;s de los formularios de esta Web o mediante mensajes de correo electr&oacute;nico, ser&aacute;n incorporados en unos tratamientos de QUESOS VEGA SOTUELAMOS, S.L. y ser&aacute;n tratados de manera informatizada y en papel.</p>

                     <p>Mediante el env&iacute;o de los formularios existentes en esta Web el remitente da su consentimiento para ser incluido en el mencionado tratamiento con el objeto de suministrarle la informaci&oacute;n solicitada en los formularios o suscripciones.</p>

                     <p>En el caso de que el usuario decida cumplimentar alg&uacute;n formulario de este sitio Web d&oacute;nde se recaben datos de car&aacute;cter personal se le informa que QUESOS VEGA SOTUELAMOS, S.L. adoptar&aacute; las medidas de &iacute;ndole t&eacute;cnica y organizativa necesarias que garanticen la seguridad de los datos suministrados a fin de evitar alteraciones, p&eacute;rdidas, tratamientos o accesos no autorizados, conforme al estado actual de la tecnolog&iacute;a y de acuerdo con lo establecido por la normativa vigente.</p>

                     <p>QUESOS VEGA SOTUELAMOS, S.L. sin embargo no puede garantizar la absoluta inexpugnabilidad del sistema, la red de acceso, Internet y por tanto la violaci&oacute;n de los datos mediante accesos fraudulentos a ellos por parte de terceros.</p>

                     <p>El usuario responder&aacute; en cualquier caso de la veracidad de la informaci&oacute;n suministrada y se hace responsable de comunicar a QUESOS VEGA SOTUELAMOS, S.L. cualquier modificaci&oacute;n sobre la misma, quedando QUESOS VEGA SOTUELAMOS, S.L. exenta de cualquier responsabilidad en este sentido.</p>

                     <p>Las finalidades de los tratamientos son:</p>

                     <p><strong>Clientes</strong>: prestarle el servicio solicitado de facturaci&oacute;n a clientes y gestionar la relaci&oacute;n mercantil con proveedores o partner. En cuanto a la legitimaci&oacute;n del tratamiento estar&aacute; regulada por el RD de 22 de agosto de 1885 por el que se publica el C&oacute;digo de Comercio y por el inter&eacute;s leg&iacute;timo del responsable. Los datos proporcionados se conservar&aacute;n mientras se mantenga la relaci&oacute;n comercial o durante un plazo de 6 a&ntilde;os a partir de la &uacute;ltima confirmaci&oacute;n de inter&eacute;s.</p>

                     <p><strong>Usuarios Web</strong>: servir como soporte de informaci&oacute;n a la gesti&oacute;n de las comunicaciones relaciones comerciales por v&iacute;a electr&oacute;nica. En cuanto a la legitimaci&oacute;n del tratamiento estar&aacute; regulada por el consentimiento del afectado y por el inter&eacute;s leg&iacute;timo del responsable. Los datos proporcionados se conservar&aacute;n hasta para dar soporte a su solicitud, la revocaci&oacute;n del consentimiento o durante los a&ntilde;os necesarios para cumplir con las obligaciones legales.</p>

                     <p><strong>Newsletter</strong>: gestionar su suscripci&oacute;n a los boletines de noticias de inter&eacute;s para usted y que remitimos peri&oacute;dicamente por email. En cuanto a la legitimaci&oacute;n del tratamiento estar&aacute; fundamentada por el consentimiento del interesado. Los datos proporcionados se conservar&aacute;n mientras no solicite el cese de los mismos y se suprimir&aacute;n cuando los datos ya no sean necesarios para los fines que fueron recogidos o durante los a&ntilde;os necesarios para cumplir con las obligaciones legales.</p>

                     <p>En el caso de que no nos facilite sus datos personales no podremos gestionar su solicitud.</p>

                     <p>La empresa podr&aacute; ceder los datos a terceros como administraci&oacute;n tributaria, bancos, cajas de ahorro y cajas rurales y organizaciones o personas directamente relacionadas con el responsable.</p>

                     <p>Si lo desea puede dirigirse a QUESOS VEGA SOTUELAMOS, S.L. con domicilio social en el Pol&iacute;gono Industrial Camporrosso calle Ciudad Real n&ordm; 3 &ndash; 02520 &ndash; Chinchilla de Montearag&oacute;n (Albacete), con una copia de DNI o documento acreditativo equivalente con el fin de ejercer los siguientes derechos:</p>

                     <ul>
                        <li>Derecho de acceso: derecho a obtener confirmaci&oacute;n sobre si QUESOS VEGA SOTUELAMOS, S.L. est&aacute; tratando datos personales que le conciernen y, en tal caso, acceder a los mismos.</li>
                        <li>Derecho de portabilidad: derecho a recibir los datos personales que nos haya facilitado en un formato estructurado de uso com&uacute;n y legible, as&iacute; como a transmitirlo a otra entidad.</li>
                        <li>Derecho de rectificaci&oacute;n: derecho a solicitar la rectificaci&oacute;n de los datos cuando sean inexactos.</li>
                        <li>Derecho de supresi&oacute;n: derecho a solicitar la supresi&oacute;n de los datos cuando ya no sean necesarios para los fines que fueron facilitados en su momento.</li>
                        <li>Derecho de oposici&oacute;n: derecho a oponerte al tratamiento de tus datos personales. QUESOS VEGA SOTUELAMOS, S.L. dejar&aacute; de tratar los datos, salvo por motivos leg&iacute;timos o en el ejercicio de posibles reclamaciones.</li>
                        <li>Derecho a la limitaci&oacute;n del tratamiento: derecho a solicitar la limitaci&oacute;n del tratamiento de sus datos.</li>
                     </ul>

                     <p>Por otro lado, si lo ha facilitado, tendr&aacute; derecho a retirar el consentimiento prestado en cualquier momento y el derecho a reclamar ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos.</p>

                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default AvisoLegal;