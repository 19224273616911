import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import "../../styles/subcategorias.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion";
const principal = "/images/cremas-de-queso-de-oveja-vega-sotuelamos.png";

// imagenes cuñas quesos
const queso1 = "/images/queso-crema-de-oveja-ahumado-tarrina-vegasoteulamos.png";
const queso2 = "/images/queso-crema-de-oveja-ajo-negro-tarrina-vegasoteulamos.png";
const queso3 = "/images/queso-crema-de-oveja-diablo-tarrina-vegasoteulamos.png";
const queso4 = "/images/queso-crema-de-oveja-miel-oregano-tarrina-vegasoteulamos.png";
const queso5 = "/images/queso-crema-de-oveja-romero-tarrina-vegasoteulamos.png";
const queso6 = "/images/queso-crema-de-oveja-trufa-tarrina-vegasoteulamos.png";

// imagenes sliders
const fondo1a = "/images/Queso-de-oveja-AHUMADO-1.png";
const fondo1b = "/images/Queso-de-oveja-AHUMADO-2.png";
const fondo5a = "/images/Queso-curado-de-oveja-con-AJO-NEGRO-1.png";
const fondo5b = "/images/Queso-curado-de-oveja-con-AJO-NEGRO-2.png";
const fondo2a = "/images/Queso-Manchego-CURADO-de-leche-de-oveja-1.png";
const fondo2b = "/images/Queso-Manchego-CURADO-de-leche-de-oveja-2.png";
const fondo3a = "/images/Queso-Manchego-ANEJO-de-leche-de-oveja-1.png";
const fondo3b = "/images/Queso-Manchego-ANEJO-de-leche-de-oveja-2.png";
const fondo9a = "/images/Queso-curado-de-oveja-con-DIABLO-1.png";
const fondo9b = "/images/Queso-curado-de-oveja-con-DIABLO-2.png";
const fondo12a = "/images/Queso-curado-de-oveja-cubierto-de-MIEL-y-OREGANO-1.png";
const fondo12b = "/images/Queso-curado-de-oveja-cubierto-de-MIEL-y-OREGANO-2.png";
const fondo13a = "/images/Queso-anejo-de-oveja-al-ROMERO-1.png";
const fondo13b = "/images/Queso-anejo-de-oveja-al-ROMERO-2.png";
const fondo6a = "/images/Queso-curado-de-oveja-con-TRUFA-NEGRA-1.png";
const fondo6b = "/images/Queso-curado-de-oveja-con-TRUFA-NEGRA-2.png";

// añade clases animate.css al hacer click para ir al producto
const commonClasses = {
   "circuloContenedor": "animate__bounceOut",
   "svg-circulo": "animate__bounceOutRight",
   "cunaQueso": "animate__bounceOutLeft",
   "tiempoMaduracion": "animate__bounceOutUp",
   "tituloQueso": "animate__bounceOutUp",
   "slider": "animate__fadeOutDown",
 };
 
 const initialItems = {
   "queso-crema-ahumado": {
     classes: { ...commonClasses },
     url: "/queso-oveja-crema-ahumado",
   },
   "queso-crema-ajo-negro": {
     classes: { ...commonClasses },
     url: "/queso-oveja-crema-ajo-negro",
   },
   "queso-crema-diablo": {
      classes: { ...commonClasses },
      url: "/queso-oveja-crema-diablo",
   },
   "queso-crema-oregano-miel": {
      classes: { ...commonClasses },
      url: "/queso-oveja-crema-oregano-miel",
   },
   "queso-crema-romero": {
      classes: { ...commonClasses },
      url: "/queso-oveja-crema-romero",
   },
   "queso-crema-trufa": {
      classes: { ...commonClasses },
      url: "/queso-oveja-crema-trufa",
   },
 };
 
 const handleCircleClick = (id) => {
   const item = initialItems[id];

   if(!isMobile && !isTablet) {
      // Seleccionar el contenedor específico por ID
      const targetElement = document.getElementById(id);
   
      for (const elementClass in item.classes) {
         const element = targetElement.querySelector(`.${elementClass}`);
      
         if (element) {
            element.classList.add(item.classes[elementClass]);
         }
      }
   
      // Aplicar la transición de opacidad
      targetElement.style.transition = 'opacity 700ms ease-in-out';
      targetElement.style.opacity = '0';

      setTimeout(() => {
        for (const elementClass in item.classes) {
          const element = targetElement.querySelector(`.${elementClass}`);
       
          if (element) {
            targetElement.style.opacity = '1';
            element.classList.remove(item.classes[elementClass]);
          }
        }
         window.location.href = item.url;
      }, 700);
   } else {
      window.location.href = item.url;
   }
};
// fin añade clases animate.css al hacer click para ir al producto

const Categorias = () => {
   const { t } = useTranslation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   }, []);

  const [moveup, setMoveup] = React.useState(false);

  return (
    <div id="categoria-cremas">
      <Header/>
      <div className="section">
        <div className="principal gradiente-quesos-crema">
          <h1 className="Titulo">
            <Trans i18nKey="tit-cat-quesos-crema" components={{ span: <span /> }} />
          </h1>
          <div className="lineaRoja"></div>
          <p className="TextoBlanco">
            <Trans i18nKey="text-cat-quesos-crema" components={{ b: <b /> }} />
          </p>
          <img
            src={principal}
            className="img-portada"
            alt="Foto cuatro cuñas de queso"
          />
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient31" id="queso-crema-ahumado">
          {/* <p className="tiempoMaduracion tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-queso-crema-ahumado" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso1} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-crema-ahumado")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
                <img src={fondo1a} className="imagenSlider" />
                <img src={fondo1b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient32" id="queso-crema-ajo-negro">
          {/* <p className="tiempoMaduracion tituloNegro tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-queso-crema-ajo-negro" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso2} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-crema-ajo-negro")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
                <img src={fondo5a} className="imagenSlider" />
                <img src={fondo5b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="section">
        <div className="quesoSubcategoria gradient33" id="queso-crema-diablo">
          {/* <p className="tiempoMaduracion text-white tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso text-white animate__animated">
            <Trans i18nKey="nombre-queso-crema-diablo" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso3} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo circuloBlanco text-white"
                onClick={() => handleCircleClick("queso-crema-diablo")}
              >
                &gt;
              </p>
              <p className="ficha-producto text-white">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
                <img src={fondo9a} className="imagenSlider" />
                <img src={fondo9b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className="section">
        <div className="quesoSubcategoria gradient34" id="queso-crema-oregano-miel">
          {/* <p className="tiempoMaduracion tituloNegro tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-queso-crema-oregano-miel" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso4} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-crema-oregano-miel")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
                <img src={fondo12a} className="imagenSlider" />
                <img src={fondo12b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className="section">
        <div className="quesoSubcategoria gradient35" id="queso-crema-romero">
          {/* <p className="tiempoMaduracion tituloNegro tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-queso-crema-romero" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso5} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-crema-romero")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
                <img src={fondo13a} className="imagenSlider" />
                <img src={fondo13b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className="section">
        <div className="quesoSubcategoria gradient36" id="queso-crema-trufa">
          {/* <p className="tiempoMaduracion tituloNegro tiempoMaduracionNegro animate__animated">8 <Trans i18nKey="meses" /></p> */}
          <h2 className="tituloQueso tituloNegro animate__animated">
            <Trans i18nKey="nombre-queso-crema-trufa" components={{ span: <span /> }} />
          </h2>
          <div className="box-images">
            <img src={queso6} className="cunaQueso animate__animated" />
            <svg
              className="svg-circulo animate__animated"
              xmlns="http://www.w3.org/2000/svg"
              xmlLang="es"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 500 500"
            >
              <defs>
                <path
                  id="textcircle"
                  d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                  transform="rotate (12,250,250)"
                />
              </defs>
              <g className="textcircle">
                <text textLength={900}>
                  <textPath xlinkHref="#textcircle" textLength={900}>
                    QUESO DE OVEJA - QUESO DE OVEJA -
                  </textPath>
                </text>
              </g>
            </svg>
            <div className="circuloContenedor animate__animated">
              <p
                className="circulo"
                onClick={() => handleCircleClick("queso-crema-trufa")}
              >
                &gt;
              </p>
              <p className="ficha-producto">
                  <Trans i18nKey="ficha-producto" />
              </p>
            </div>
          </div>
          <motion.div className="slider animate__animated">
            <motion.div
              className="fondoTransicionSlider"
              animate={{ height: moveup ? "0%" : "100%" }}
            >
              <motion.div
                className="sliderDiv"
                animate={{ y: -906 }}
                transition={{ ease: "linear", duration: 10, repeat: Infinity }}
              >
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
                <img src={fondo6a} className="imagenSlider" />
                <img src={fondo6b} className="imagenSlider" />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>


      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Categorias;
