import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import Footer from './components/Footer';
// paginas
import Somos from './pages/Somos';
import Innovacion from './pages/Innovacion';
import Premios from './pages/Premios';
import Ovejas from './pages/Ovejas';
import Contacto from './pages/Contacto';
import Prensa from './pages/Prensa';
import AvisoLegal from './pages/AvisoLegal';
import PoliticaProteccionDatos from './pages/PoliticaProteccionDatos';
import Cookies from './pages/Cookies';
import Calidad from './pages/Calidad';
// categorias
import QuesosManchegos from './pages/categorias/QuesosManchegos';
import QuesosManchegosEcologicos from './pages/categorias/QuesosManchegosEcologicos';
import QuesosManchegosLecheCruda from './pages/categorias/QuesosManchegosLecheCruda';
import QuesosOveja from './pages/categorias/QuesosOveja';
import QuesosOvejaLecheCruda from './pages/categorias/QuesosOvejaLecheCruda';
import QuesosOvejaRecetas from './pages/categorias/QuesosOvejaRecetas';
import QuesosOvejaCrema from './pages/categorias/QuesosOvejaCrema';
// quesos
import QuesoOvejaTierno from './pages/quesos/QuesoOvejaTierno';
import QuesoOvejaSemicurado from './pages/quesos/QuesoOvejaSemicurado';
import QuesoOvejaCurado from './pages/quesos/QuesoOvejaCurado';
import QuesoOvejaAnejo from './pages/quesos/QuesoOvejaAnejo';
import QuesoManchegoSemicurado from './pages/quesos/QuesoManchegoSemicurado';
import QuesoManchegoCurado from './pages/quesos/QuesoManchegoCurado';
import QuesoManchegoAnejo from './pages/quesos/QuesoManchegoAnejo';
import QuesoManchegoEcologicoSemicurado from './pages/quesos/QuesoManchegoEcologicoSemicurado';
import QuesoManchegoEcologicoCurado from './pages/quesos/QuesoManchegoEcologicoCurado';
import QuesoManchegoCrudaCurado from './pages/quesos/QuesoManchegoCrudaCurado';
import QuesoManchegoCrudaSemicurado from './pages/quesos/QuesoManchegoCrudaSemicurado';
import QuesoManchegoCrudaAnejo from './pages/quesos/QuesoManchegoCrudaAnejo';
import QuesoOvejaCrudaCurado from './pages/quesos/QuesoOvejaCrudaCurado';
import QuesoOvejaCrudaSemicurado from './pages/quesos/QuesoOvejaCrudaSemicurado';
import QuesoOvejaCrudaAnejo from './pages/quesos/QuesoOvejaCrudaAnejo';
import QuesoOvejaAhumado from './pages/quesos/QuesoOvejaAhumado';
import QuesoOvejaAnchoa from './pages/quesos/QuesoOvejaAnchoa';
import QuesoOvejaBoletus from './pages/quesos/QuesoOvejaBoletus';
import QuesoOvejaAceitunaNegra from './pages/quesos/QuesoOvejaAceitunaNegra';
import QuesoOvejaAjoNegro from './pages/quesos/QuesoOvejaAjoNegro';
import QuesoOvejaTrufaNegra from './pages/quesos/QuesoOvejaTrufaNegra';
import QuesoOvejaCrudaTrufaNegra from './pages/quesos/QuesoOvejaCrudaTrufaNegra';
import QuesoOvejaTrufaBlanca from './pages/quesos/QuesoOvejaTrufaBlanca';
import QuesoOvejaDiablo from './pages/quesos/QuesoOvejaDiablo';
import QuesoOvejaJalapenos from './pages/quesos/QuesoOvejaJalapenos';
import QuesoOvejaInfierno from './pages/quesos/QuesoOvejaInfierno';
import QuesoOvejaMielOregano from './pages/quesos/QuesoOvejaMielOregano';
import QuesoOvejaRomero from './pages/quesos/QuesoOvejaRomero';
import QuesoOvejaTradicion from './pages/quesos/QuesoOvejaTradicion';
import QuesoCremaAhumado from './pages/quesos/QuesoCremaAhumado';
import QuesoCremaAjoNegro from './pages/quesos/QuesoCremaAjoNegro';
import QuesoCremaDiablo from './pages/quesos/QuesoCremaDiablo';
import QuesoCremaOreganoMiel from './pages/quesos/QuesoCremaOreganoMiel';
import QuesoCremaRomero from './pages/quesos/QuesoCremaRomero';
import QuesoCremaTrufa from './pages/quesos/QuesoCremaTrufa';

function App() {

  return (
   <Router>
      <Routes>
         <Route path='/' element={<Home />} />
         {/* <Route path='/subcategorias' element={<SubCategorias />} /> */}

         {/* PAGINAS */}
         <Route path='/somos' element={<Somos />} />
         <Route path='/innovacion' element={<Innovacion />} />
         <Route path='/premios' element={<Premios />} />
         <Route path='/ovejas' element={<Ovejas />} />
         <Route path='/contacto' element={<Contacto />} />
         <Route path='/prensa' element={<Prensa />} />
         <Route path='/aviso-legal' element={<AvisoLegal />} />
         <Route path='/politica-de-proteccion-de-datos' element={<PoliticaProteccionDatos />} />
         <Route path='/cookies' element={<Cookies />} />
         <Route path='/politica-de-calidad' element={<Calidad />} />

         {/* CATEGORIAS */}
         <Route path='/quesos-manchegos' element={<QuesosManchegos />} />
         <Route path='/quesos-manchegos-ecologicos' element={<QuesosManchegosEcologicos />} />
         <Route path='/quesos-manchegos-leche-cruda' element={<QuesosManchegosLecheCruda />} />
         <Route path='/quesos-oveja' element={<QuesosOveja />} />
         <Route path='/quesos-oveja-leche-cruda' element={<QuesosOvejaLecheCruda />} />
         <Route path='/quesos-oveja-recetas' element={<QuesosOvejaRecetas />} />
         <Route path='/quesos-oveja-crema' element={<QuesosOvejaCrema />} />

         {/* QUESOS */}
         <Route path='/queso-oveja-tierno' element={<QuesoOvejaTierno />} />
         <Route path='/queso-oveja-semicurado' element={<QuesoOvejaSemicurado />} />
         <Route path='/queso-oveja-curado' element={<QuesoOvejaCurado />} />
         <Route path='/queso-oveja-anejo' element={<QuesoOvejaAnejo />} />
         <Route path='/queso-manchego-semicurado' element={<QuesoManchegoSemicurado />} />
         <Route path='/queso-manchego-curado' element={<QuesoManchegoCurado />} />
         <Route path='/queso-manchego-anejo' element={<QuesoManchegoAnejo />} />
         <Route path='/queso-manchego-ecologico-semicurado' element={<QuesoManchegoEcologicoSemicurado />} />
         <Route path='/queso-manchego-ecologico-curado' element={<QuesoManchegoEcologicoCurado />} />
         <Route path='/queso-manchego-leche-cruda-curado' element={<QuesoManchegoCrudaCurado />} />
         <Route path='/queso-manchego-leche-cruda-semicurado' element={<QuesoManchegoCrudaSemicurado />} />
         <Route path='/queso-manchego-leche-cruda-anejo' element={<QuesoManchegoCrudaAnejo />} />
         <Route path='/queso-oveja-leche-cruda-curado' element={<QuesoOvejaCrudaCurado />} />
         <Route path='/queso-oveja-leche-cruda-semicurado' element={<QuesoOvejaCrudaSemicurado />} />
         <Route path='/queso-oveja-leche-cruda-anejo' element={<QuesoOvejaCrudaAnejo />} />
         <Route path='/queso-oveja-ahumado' element={<QuesoOvejaAhumado />} />
         <Route path='/queso-oveja-anchoa' element={<QuesoOvejaAnchoa />} />
         <Route path='/queso-oveja-boletus' element={<QuesoOvejaBoletus />} />
         <Route path='/queso-oveja-aceituna-negra' element={<QuesoOvejaAceitunaNegra />} />
         <Route path='/queso-oveja-ajo-negro' element={<QuesoOvejaAjoNegro />} />
         <Route path='/queso-oveja-trufa-negra' element={<QuesoOvejaTrufaNegra />} />
         <Route path='/queso-oveja-leche-cruda-trufa-negra' element={<QuesoOvejaCrudaTrufaNegra />} />
         <Route path='/queso-oveja-trufa-blanca' element={<QuesoOvejaTrufaBlanca />} />
         <Route path='/queso-oveja-diablo' element={<QuesoOvejaDiablo />} />
         <Route path='/queso-oveja-jalapenos' element={<QuesoOvejaJalapenos />} />
         <Route path='/queso-oveja-infierno' element={<QuesoOvejaInfierno />} />
         <Route path='/queso-oveja-miel-oregano' element={<QuesoOvejaMielOregano />} />
         <Route path='/queso-oveja-romero' element={<QuesoOvejaRomero />} />
         <Route path='/queso-oveja-tradicion' element={<QuesoOvejaTradicion />} />
         <Route path='/queso-oveja-crema-ahumado' element={<QuesoCremaAhumado />} />
         <Route path='/queso-oveja-crema-ajo-negro' element={<QuesoCremaAjoNegro />} />
         <Route path='/queso-oveja-crema-diablo' element={<QuesoCremaDiablo />} />
         <Route path='/queso-oveja-crema-oregano-miel' element={<QuesoCremaOreganoMiel />} />
         <Route path='/queso-oveja-crema-romero' element={<QuesoCremaRomero />} />
         <Route path='/queso-oveja-crema-trufa' element={<QuesoCremaTrufa />} />

         {/* Redirecciones */}
         <Route path="/productos" element={<Navigate to="/" />} />
         <Route path="/productos/?type=do" element={<Navigate to="/quesos-manchegos" />} />
         <Route path="/productos/?type=ma" element={<Navigate to="/quesos-oveja" />} />
         <Route path="/productos/?type=es" element={<Navigate to="/quesos-oveja-recetas" />} />
         <Route path="/empresa" element={<Navigate to="/somos" />} />
         <Route path="/contacto" element={<Navigate to="/contacto" />} />
         <Route path="/productos/queso-semicurado-de-oveja-2-3-meses" element={<Navigate to="/queso-oveja-semicurado" />} />
         <Route path="/productos/queso-anejo-artesano-de-oveja-manchega-10-12-meses-leche-cruda" element={<Navigate to="/queso-manchego-leche-cruda-anejo" />} />
         <Route path="/productos/queso-de-oveja-curado-con-trufa" element={<Navigate to="/queso-oveja-trufa-negra" />} />
         <Route path="/productos/queso-anejo-envejecido-al-romero-10-12-meses" element={<Navigate to="/queso-oveja-romero" />} />
         <Route path="/aviso-legal" element={<Navigate to="/aviso-legal" />} />
         <Route path="/politica-privacidad" element={<Navigate to="/politica-de-proteccion-de-datos" />} />
         <Route path="/politica-cookies" element={<Navigate to="/cookies" />} />
         <Route path="/productos/queso-semicurado-de-oveja-manchega-2-3-meses" element={<Navigate to="/queso-oveja-leche-cruda-semicurado" />} />
         <Route path="/productos/queso-curado-de-oveja-manchega-4-6-meses" element={<Navigate to="/queso-oveja-leche-cruda-curado" />} />
         <Route path="/productos/queso-anejo-de-oveja-manchega-9-12-meses" element={<Navigate to="/queso-oveja-leche-cruda-anejo" />} />
         <Route path="/productos/queso-semicurado-artesano-de-oveja-manchega-2-3-meses-leche-cruda" element={<Navigate to="/queso-oveja-leche-cruda-semicurado" />} />
         <Route path="/productos/queso-curado-artesano-de-oveja-manchega-5-6-meses-leche-cruda" element={<Navigate to="/queso-oveja-leche-cruda-curado" />} />
         <Route path="/productos/queso-anejo-artesano-de-oveja-5-6-meses-leche-cruda" element={<Navigate to="/queso-oveja-leche-cruda-anejo" />} />
         <Route path="/author/synergy" element={<Navigate to="/" />} />
         <Route path="/productos/tarro-de-queso-curado-en-aceite-de-oliva-400-g" element={<Navigate to="/" />} />
         <Route path="/productos/tarro-de-queso-curado-en-aceite-de-oliva-con-romero-400-g" element={<Navigate to="/" />} />
         <Route path="/productos/queso-en-barra-vega-sotuelamos" element={<Navigate to="/" />} />
         <Route path="/productos/queso-de-cabra-rico-en-calcio" element={<Navigate to="/" />} />
         <Route path="/productos/queso-curado-con-aceituna-negra" element={<Navigate to="/queso-oveja-aceituna-negra" />} />
         <Route path="/productos/queso-de-oveja-curado-con-ajo-negro" element={<Navigate to="/queso-oveja-ajo-negro" />} />
         <Route path="/productos/queso-de-oveja-con-boletus" element={<Navigate to="/queso-oveja-boletus" />} />
         <Route path="/productos/queso-tierno-de-oveja-envasado-al-vacio" element={<Navigate to="/queso-oveja-tierno" />} />
         <Route path="/productos/queso-tierno-de-oveja-en-corteza-natural" element={<Navigate to="/queso-oveja-tierno" />} />
         <Route path="/productos/queso-de-oveja-tierno-sin-lactosa" element={<Navigate to="/" />} />
         <Route path="/productos/queso-semicurado-de-oveja-sin-lactosa" element={<Navigate to="/" />} />
         <Route path="/productos/queso-curado-de-oveja-5-6-meses" element={<Navigate to="/queso-oveja-curado" />} />
         <Route path="/productos/queso-anejo-de-oveja-10-12-meses" element={<Navigate to="/queso-oveja-anejo" />} />
         <Route path="/productos/queso-semicurado-artesano-de-oveja5-6" element={<Navigate to="/queso-oveja-leche-cruda-semicurado" />} />
         <Route path="/productos/queso-curado-artesano-de-oveja-5-6-meses-leche-cruda" element={<Navigate to="/queso-oveja-leche-cruda-curado" />} />
         <Route path="/productos/queso-anejo-artesano-de-oveja-5-6-meses-leche-cruda" element={<Navigate to="/queso-oveja-leche-cruda-anejo" />} />
         <Route path="/author/synergy" element={<Navigate to="/" />} />
      </Routes>
   </Router>
  );
}

export default App;