import React, {useState, useEffect, useRef } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const AvisoLegal = () => {
   const { t } = useTranslation(); 
   
   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="simple-page">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1><Trans i18nKey="aviso-legal-titulo" /></h1>
                     <h2>DATOS GENERALES</h2>

                     <p>QUESOS VEGA SOTUELAMOS, S.L. como titular del sitio web y de acuerdo con el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico pone a su disposición los siguientes datos:</p>
                     <p>
                        Su denominación social es QUESOS VEGA SOTUELAMOS, S.L.<br/>
                        Su CIF es B02265973<br/>
                        DATOS REGISTRALES: Tomo: 929, Libro: 693, Folio 43, Sección: 8, Hoja: AB-23195, Inscripción 1ª.<br/>
                        Su domicilio social está en el Polígono Industrial Camporrosso calle Ciudad Real nº 3 – 02520 – Chinchilla de Montearagón (Albacete).
                     </p>
                     <p>QUESOS VEGA SOTUELAMOS, S.L. informa que la Web www.quesosvegasotuelamos.com tiene por objeto facilitar, al público en general, el conocimiento de las actividades que esta empresa realiza y de los productos y servicios que presta.</p>

                     <h2>CONDICIONES DE USO</h2>

                     <p>El acceso y utilización de este sitio Web le atribuye la condición de usuario, aceptando con dicho acceso las Condiciones de Uso expuestas a continuación.</p>
                     <p>Este sitio Web proporciona al usuario acceso a información y contenidos sobre los que el usuario asume la responsabilidad, extendiéndose esta al proceso de registro que pudiera ser necesario para acceder a determinados servicios o contenidos.</p>
                     <p>En el proceso de registro el usuario será igualmente responsable de facilitar información veraz, legítima y actualizada, así como de hacer un uso diligente y confidencial de los datos de acceso proporcionados.</p>
                     <p>El usuario se compromete a hacer un uso adecuado de la información, contenidos y servicios del presente sitio Web y en especial a no realizar ninguna acción que pudiera causar daños físicos o lógicos al sistema así como intentar acceder al mismo de modo fraudulento utilizando datos de acceso no autorizados.</p>

                     <h2>RESPONSABILIDADES</h2>
                     <p>Tanto el acceso a esta Web como el uso que pueda hacerse de la información contenida en el mismo es de la exclusiva responsabilidad de quien lo realiza. El usuario será responsable de todas las acciones que realice con su identificador de usuario. </p>
                     <p>En concreto, será responsable de escoger, como clave y como recordatorio de la misma, contraseñas y frases robustas, esto es, cifras y letras e incluso, en los sistemas que lo permitan, signos de puntuación y caracteres especiales, difíciles de adivinar. En especial, el usuario evitará escoger palabras del diccionario, palabras que estén relacionadas con él mismo (nombre de familiar, domicilio, fecha de nacimiento, etc.) o sencillas de adivinar (combinaciones de nombres con meses, prefijos y sufijos, etc.). QUESOS VEGA SOTUELAMOS, S.L. no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso de la información.</p>
                     <p>QUESOS VEGA SOTUELAMOS, S.L. se reserva el derecho de efectuar sin previo aviso y sin asumir alguna responsabilidad por ello las modificaciones que considere oportunas en su sitio Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados.</p>
                     <p>QUESOS VEGA SOTUELAMOS, S.L. no garantiza la inexistencia de errores en el acceso a la Web, en su contenido, ni que éste se encuentre actualizado, aunque desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos.</p>
                     <p>QUESOS VEGA SOTUELAMOS, S.L. no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>

                     <h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>

                     <p>QUESOS VEGA SOTUELAMOS, S.L. es propietaria o dispone de los debidos permisos relativos a los derechos de propiedad intelectual de este sitio Web y de los elementos contenidos en el mismo: textos, imágenes, sonidos, vídeos, marcas, logotipos, diseño, etc. En virtud de lo dispuesto en los artículos 8 y 32.1 de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de este sitio Web en cualquier soporte y por cualquier medio técnico, sin la autorización de QUESOS VEGA SOTUELAMOS, S.L. y/o de sus respectivos autores o titulares de los derechos.</p>
                     <p>El usuario podrá visualizar, imprimir, copiar y almacenar los elementos de este sitio Web siempre y cuando sea, única y exclusivamente, para su uso personal y privado.</p>
                     <p>La utilización no autorizada de la información contenida en esta Web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de QUESOS VEGA SOTUELAMOS, S.L., dará lugar a las responsabilidades legalmente establecidas.</p>
                     <p>Todos los productos y servicios de estas páginas que NO son propiedad de QUESOS VEGA SOTUELAMOS, S.L. son marcas registradas por sus respectivos propietarios y son reconocidas como tales por nuestra empresa. Solamente aparecen en la web de QUESOS VEGA SOTUELAMOS, S.L. a efectos de promoción y de recopilación de información. Estos propietarios pueden solicitar la modificación o eliminación de la información que les pertenece.</p>

                     <h2>LEY APLICABLE Y JURISDICCIÓN</h2>
                     <p>El presente aviso legal y condiciones generales se rigen por la legislación Española y cualquier tipo de controversia o litigio relativo al uso de este sitio Web será interpretado conforme a las leyes vigentes en el lugar de residencia del propietario de este sitio web, teniendo competencia para la resolución de todo litigio o conflicto que pueda surgir los Juzgados y Tribunales competentes dentro del ámbito territorial Español, renunciando expresamente el usuario a cualquier otro fuero.</p>
                  </div>
               </div>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default AvisoLegal;