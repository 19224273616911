import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
   .use(initReactI18next) // Conecta react-i18next
   .init({
      resources: {
         en: { translation: require("./locales/en/translation.json") },
         es: { translation: require("./locales/es/translation.json") },
         // Agrega más idiomas aquí si es necesario
      },
      lng: "es", // Idioma predeterminado
      fallbackLng: "es", // Idioma de respaldo
      interpolation: {
         escapeValue: false, // Permite HTML en las traducciones
      },
   });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
