import React, {useState, useEffect, useRef } from "react";
import "../styles/innovacion.scoped.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation, Trans } from 'react-i18next';

const tarro = "/images/innovacion-inconformismo-tarro.png";
const queso = "/images/innovacion-inconformismo-queso.png";
const oveja = "/images/innovacion-inconformismo-oveja.png";
const quesoID = "/images/home_queso_innovacion.png";
const laboratorio = "/images/innovacion-laboratorio.png";

const Somos = () => {
   const { t } = useTranslation(); 

   useEffect(() => {
      // Objeto con IDs de elementos como claves y clases correspondientes como valores
      const elementosClases = {
         'num1-animate': 'animate__fadeInLeft',
         'num2-animate': 'animate__fadeInLeft',
         'num3-animate': 'animate__fadeInLeft',
         'num4-animate': 'animate__fadeInLeft',
         'queso-diferenciacion-animate': 'animate__fadeInDown',
         'img-tarro-animate': 'animate__backInDown',
         'titulo-tarro-animate': 'animate__fadeInUp',
         'texto-tarro-animate': 'animate__fadeInUp',
         'img-queso-animate': 'animate__backInDown',
         'titulo-queso-animate': 'animate__fadeInUp',
         'texto-queso-animate': 'animate__fadeInUp',
         'img-oveja-animate': 'animate__backInDown',
         'titulo-oveja-animate': 'animate__fadeInUp',
         'texto-oveja-animate': 'animate__fadeInUp',
         'laboratorio-animate': 'animate__lightSpeedInRight',
      };

      // Función para verificar si un elemento es visible en pantalla y agregar la clase de CSS si es así
      const agregarClaseSiVisible = () => {
         Object.keys(elementosClases).forEach((id) => {
         const elemento = document.getElementById(id);

         // Verificamos si el elemento existe en el DOM antes de intentar obtener su posición
         if (elemento) {
            const elementoPosicion = elemento.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (elementoPosicion < windowHeight) {
               elemento.classList.add(elementosClases[id]);
            } else {
               elemento.classList.remove(elementosClases[id]);
            }
         }
         });
      };

      // Agregamos el evento de 'scroll' al documento para verificar cada vez que se realiza un scroll
      document.addEventListener('scroll', agregarClaseSiVisible);

      // Limpieza: Eliminamos el evento de 'scroll' cuando el componente se desmonta
      return () => {
         document.removeEventListener('scroll', agregarClaseSiVisible);
      };
   }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

   return(
      <div>
         <Header/>
         <div className="espacioHeader"></div>
         <div className="cabeceraBig bgInnovacion overlay">
            <div className="cabeceraTituloContenedor">
               <h1 className="cabecera animate__animated animate__fadeIn">
                  <Trans i18nKey="innovamos-sorprender" components={{ span: <span /> }} />
               </h1>
               <p className="cabeceraSubtitulo animate__animated animate__fadeInUp">
                  <Trans i18nKey="repensamos" />
               </p>
            </div>
         </div>
         <div className="inconformistas">
            <h1 className="titulo">
               <Trans i18nKey="inconformistas" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mt-3 mb-4"></div>
            <p className="texto">
               <Trans i18nKey="ser-unicos" components={{ b: <b /> }} />
            </p>
            <div className="imagenes">
               <div className="imagen">
                  <img src={tarro} className="animate__animated hideEl" alt="imagen tecnología" id="img-tarro-animate"/>
                  <p className="tituloI animate__animated hideEl" id="titulo-tarro-animate"><Trans i18nKey="innovacion-titulo1" /></p>
                  <p className="texto animate__animated hideEl" id="texto-tarro-animate"><Trans i18nKey="innovacion-texto1" components={{ b: <b /> }} /></p>
               </div>
               <div className="imagen">
                  <img src={queso} className="animate__animated hideEl" alt="imagen tecnología" id="img-queso-animate"/>
                  <p className="tituloI animate__animated hideEl" id="titulo-queso-animate"><Trans i18nKey="innovacion-titulo2" /></p>
                  <p className="texto animate__animated hideEl" id="texto-queso-animate"><Trans i18nKey="innovacion-texto2" components={{ b: <b /> }} /></p>
               </div>
               <div className="imagen">
                  <img src={oveja} className="animate__animated hideEl" alt="imagen tecnología" id="img-oveja-animate"/>
                  <p className="tituloI animate__animated hideEl" id="titulo-oveja-animate"><Trans i18nKey="innovacion-titulo3" /></p>
                  <p className="texto animate__animated hideEl" id="texto-oveja-animate"><Trans i18nKey="innovacion-texto3" components={{ b: <b /> }} /></p>
               </div>
            </div>
         </div>
         <div className="impacto">
            <h1 className="titulo">
               <Trans i18nKey="impacto-positivo" components={{ span: <span /> }} />
            </h1>
            <div className="lineaRojaQuesos mb-4"></div>
            <div className="impactos">
               <div className="paso">
                  <div className="numero animate__animated hideEl" id="num1-animate">
                     <p>1.</p>
                  </div>
                  <div className="contenido">
                     <p className="tituloP"><Trans i18nKey="plasticos" /></p>
                     <p className="texto">
                        <Trans i18nKey="envases-text" />
                     </p>
                  </div>
               </div>
               <div className="paso">
                  <div className="numero animate__animated hideEl" id="num2-animate">
                     <p>2.</p>
                  </div>
                  <div className="contenido">
                     <p className="tituloP"><Trans i18nKey="consumo-agua" /></p>
                     <p className="texto">
                        <Trans i18nKey="consumo-agua-text" />
                     </p>
                  </div>
               </div>
               <div className="paso">
                  <div className="numero animate__animated hideEl" id="num3-animate">
                     <p>3.</p>
                  </div>
                  <div className="contenido">
                     <p className="tituloP"><Trans i18nKey="sostenible" /></p>
                     <p className="texto">
                        <Trans i18nKey="sostenible-text" />
                     </p>
                  </div>
               </div>
               <div className="paso">
                  <div className="numero animate__animated hideEl" id="num4-animate">
                     <p>4.</p>
                  </div>
                  <div className="contenido">
                     <p className="tituloP"><Trans i18nKey="materia-prima" /></p>
                     <p className="texto">
                        <Trans i18nKey="materia-prima-text" />
                     </p>
                  </div>
               </div>
            </div>
         </div>            
         <div className="idi">
            <img src={quesoID} alt="queso diferenciacion" className="animate__animated hideEl" id="queso-diferenciacion-animate"/>
            <h1 className="titulo">
               <Trans i18nKey="nuestro-idi" />
            </h1>
            <p className="texto"><Trans i18nKey="nuestro-idi-text" /></p>
         </div>
         <div className="laboratorio">
            <h1 className="titulo"><Trans i18nKey="nuestro-laboratorio" components={{ span: <span /> }} /></h1>
            <p className="texto">
               <Trans i18nKey="nuestro-laboratorio-text" components={{ b: <b /> }} />
            </p>
            <div className="imagen">
               <img src={laboratorio} className="animate__animated" alt="laboratorio" id="laboratorio-animate"/>
            </div>
         </div>
         <Footer/>
      </div>
   )
};

export default Somos;